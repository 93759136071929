let baseURL = 'https://chess-la.s3.us-west-1.amazonaws.com/thumbnails';

export const staticPlaceInfo = {
    "chess_park_lounge": { 
        id: "chess_park_lounge",
        name: "Chess Park Lounge",
        photo_thumbnail: `${baseURL}/thumbnail_chess_park_lounge.jpg`,
        position: { lat: 34.14918002263324, lng: -118.25542975535383 },
        boardType: "store",
        neighborhood: "Glendale",
        notes: `
        From 2004 - 2018, there was a <a href="https://www.glendaleparksfoundation.org/parks/chess-park/" target="_blank">chess park</a> along 
        an alleyway in downtown Glendale with 16 cement chess tables. In October 
        of 2018, the city chose to <a href="https://twitter.com/chesspark_2017/status/1053018407149293568" target="_blank">demolish</a> the 
        park to make way for new development.
        <br />
        <br />
        While the demolision of the chess park is a travesty, the new bar and resturant that was 
        <a href="https://www.latimes.com/socal/glendale-news-press/news/tn-gnp-me-chess-park-tavern-opening-20190110-story.html" target="_blank">built in its place</a> has 
        thankfully at least chosen to maintain the chess theme of the area.
        <br />
        <br />
        The bar is actually very impressive and makes a fun night out for chess enthusiasts. There are many small details 
        of the building that are designed or decorated with chess themes in mind. LA Times mentions that CPL also 
        intends to host chess nights once or twice a month, although this does not seem to be happening yet. 
        <br />
        <br />
        In the hallway that leads to the bathroom, there is some very bizarre chess (and maybe Alice in Wonderland...?) themed
        art, as well as a hall of grandmasters. My three personal favorites--Pia Cramling, Judit Polgár, and Vera Menchik--are all 
        there, as well as fan favorite Hikaru Nakamura.
        <br />
        <br />
        On Friday and Saturday nights, CPL is very popular and wait times can exceed an hour. If you'd like to visit, be 
        sure to get a reservation ahead of time.
        `,
        purchase_required: true,
        verified: true,
        last_confirmed_seen: "2022",
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/9LQG7p1p6UaG4zBB6" },
            { iconType: "website", url: "https://cplglendale.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/chessparklounge/" },
            { iconType: "twitter", url: "https://twitter.com/ChessParkLounge" },
            { iconType: "facebook", url: "https://www.facebook.com/ChessParkLounge/" },
        ],
        images: [
            {
                "id": "CPL1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2825.jpg"
            },
            {
                "id": "CPL12",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2858.jpg"
            },
            {
                "id": "CPL4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2831.jpg"
            },
            {
                "id": "CPL17",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2876.jpg"
            },
            {
                "id": "CPL13",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2860.jpg"
            },
            {
                "id": "CPL3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2827.jpg"
            },
            {
                "id": "CPL5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2836.jpg"
            },
            {
                "id": "CPL6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2839.jpg"
            },
            {
                "id": "CPL11",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2855.jpg"
            },
            {
                "id": "CPL16",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2875.jpg"
            },
            {
                "id": "CPL14",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2864.jpg"
            },
            {
                "id": "CPL15",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2873.jpg"
            },
            {
                "id": "CPL18",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2881.jpg"
            },
            {
                "id": "CPL19",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2898.jpg"
            },
            {
                "id": "CPL7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2843.jpg"
            },
            {
                "id": "CPL8",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2845.jpg"
            },
            {
                "id": "CPL9",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2846.jpg"
            },
            {
                "id": "CPL10",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_park_lounge/IMG_2853.jpg"
            },
        ]
    },
    "cafe_stories": {
        id: "cafe_stories",
        name: "Stories",
        photo_thumbnail: `${baseURL}/thumbnail_stories.jpg`,
        position: { lat: 34.07720574316338, lng: -118.25919741800962 },
        boardType: "board",
        notes: `One folding board available on the cafe counter near the register at the rear of the 
        store. Pieces are available inside of the board. The board is aged with some squares beginning
        to separate from the base. The black pieces are missing a rook which has been replaced with a
        piece from the game "Sorry".`,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Echo Park",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/FdxoB1AQ5waF8kSG6" },
            { iconType: "website", url: "https://storiesla.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/storiesbooksandcafe/" },
            { iconType: "twitter", url: "https://twitter.com/storiesechopark" },
            { iconType: "facebook", url: "https://www.facebook.com/storiesla/" },  
        ],
        images: [
            {
                "id": "IMG_5626",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/cafe_stories/IMG_5626.jpg"
            },
            {
                "id": "IMG_5628",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/cafe_stories/IMG_5628.jpg"
            },
            {
                "id": "IMG_5629",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/cafe_stories/IMG_5629.jpg"
            },
        ]
    },
    "sm_chess_park": {
        id: "sm_chess_park",
        name: "Chess Park, Santa Monica",
        photo_thumbnail: `${baseURL}/thumbnail_sm_chess_park.jpg`,
        position: { lat: 34.009311, lng: -118.494821 },
        boardType: "oversize",
        notes: `Unfortunately, the famous chess tables have been removed by the city in a misguided attempt 
        to prevent crime. However, there is still an oversize concrete board installed in the ground and cages 
        full of oversize pieces. The key for the cages can be requested from the Police Substation next to the pier. Unfortunately the 
        smaller wooden tables have all been <a href="https://smmirror.com/2022/02/santa-monicas-chess-park-set-to-close/"
        target="_blank">removed by the city</a> and are no longer available for play. YouTube user 
        <a href="https://www.youtube.com/c/FabianLewkowiczSantaMonicaCloseup" target="_blank">SantaMonicaCloseUp</a> 
        caught the demolishion on 
        <a href="https://www.youtube.com/watch?v=_LQZQaEK_3Q" target="_blank">film</a>.
        `,
        purchase_required: false,
        pieces: "Available upon request.",
        last_confirmed_seen: "2022",
        neighborhood: "Santa Monica",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/2dzqSVWni8tNFDTc8" },
            { iconType: "website", url: "https://www.smgov.net/Departments/CCS/content.aspx?id=32743" },
        ],
        images: [
            {
                "id": "IMG_5525",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5525.jpg"
            },
            {
                "id": "IMG_5522",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5522.jpg"
            },
            {
                "id": "IMG_5532",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5532.jpg"
            },
            {
                "id": "IMG_5527",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5527.jpg"
            },
            {
                "id": "IMG_5529",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5529.jpg"
            },
            {
                "id": "IMG_5530",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5530.jpg"
            },
            {
                "id": "IMG_5531",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5531.jpg"
            },
            {
                "id": "IMG_5528",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/santa_monica_chess_park/IMG_5528.jpg"
            },
        ]
    },
    "pv_chess_park": {
        id: "pv_chess_park",
        name: "Chess Park, Playa Vista",
        photo_thumbnail: `${baseURL}/thumbnail_pv_chess_park.jpg`,
        position: { lat: 33.975464, lng: -118.421928 },
        boardType: "table",
        notes: `This park is fairly new, and was built as a part of the new "Silicon Beach" 
        development in Playa Vista. The park is public, but visitors must navigate through 
        the neighborhood to access it, as it's walled off from the major nearby roads. 
        <br />
        The chess tables are in the same area as a grassy playground for young children, 
        which can make it easy to miss. Walk through the small chain link gate to reach the tables. 
        <br />
        The center of the park has checkered tiles which can function as an oversize board for 
        outdoor play. Oversize pieces however were not available when I visited. It's possible 
        they were in one of the nearby storage buildings. The park is also bordered by five cement 
        chess tables with tile boards inlaid. Pieces are not provided. Several large trees were planted 
        around the park, so the area is very shady and pleasant.
        <br />
        If you visit, look around the park and notice that many of the features of the park itself
        are disguised to look like chess pieces.  This was so well done that I didn't even notice
        them until I was leaving the park.`,
        purchase_required: false,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/gPWf1fK7pZZDCSLb8" },
            { iconType: "instagram", url: "https://www.instagram.com/explore/locations/337765849/chess-park-playa-vista/" },
        ],
        neighborhood: "Playa Vista",
        images: [
            {
                "id": "IMG_6124",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6124.jpg"
            },
            {
                "id": "IMG_6115",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6115.jpg"
            },
            {
                "id": "IMG_6116",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6116.jpg"
            },
            {
                "id": "IMG_6121",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6121.jpg"
            },
            {
                "id": "IMG_6119",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6119.jpg"
            },
            {
                "id": "IMG_6120",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6120.jpg"
            },
            {
                "id": "IMG_6134",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6134.jpg"
            },
            {
                "id": "IMG_6137",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6137.jpg"
            },
            {
                "id": "IMG_6128",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6128.jpg"
            },
            {
                "id": "IMG_6130",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6130.jpg"
            },
            
            {
                "id": "IMG_6135",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6135.jpg"
            },
            {
                "id": "IMG_6144",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6144.jpg"
            },
            {
                "id": "IMG_6145",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6145.jpg"
            },
            {
                "id": "IMG_6146",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6146.jpg"
            },
            {
                "id": "IMG_6148",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6148.jpg"
            },
            {
                "id": "IMG_6143",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/playa_vista_chess_park/IMG_6143.jpg"
            },
        ]
    },
    "ucla_knudsen_hall": {
        id: "ucla_knudsen_hall",
        name: "Knudsen Hall",
        photo_thumbnail: `${baseURL}/thumbnail_ucla_knudsen_hall.jpg`,
        position: { lat: 34.0705889, lng: -118.4432858 },
        boardType: "board",
        neighborhood: "Westwood",
        day: "",
        notes: "A binder of chess puzzles are available alongside a small wooden board on the 3rd floor of Knudsen Hall at UCLA.",
        purchase_required: false,
        pieces: "Provided",
        last_confirmed_seen: "2022",
        kids_only: false,
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/kexHVEnqzH2Tiopm8" },
        ],
        images: [
            {
                "id": "IMG_6097",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ucla_knudsen_hall/IMG_6097.jpg"
            },
        ]
    },
    "ivy_station_game_cart": {
        id: "ivy_station_game_cart",
        name: "Ivy Station Game Cart",
        photo_thumbnail: `${baseURL}/thumbnail_ivy_station_game_cart.jpg`,
        position: { lat: 34.028288, lng: -118.388626 },
        boardType: "board",
        neighborhood: "Culver City",
        day: "Mondays, Tuesdays & Thursdays 11:00am - 4:00pm. Saturdays 10:00am - 3:00pm.",
        notes: `Folding tables set up next to carts with books and games. The center cart has a 
        mousepad-style tournament board and a plastic container of plastic lightly-weighted pieces.`,
        purchase_required: false,
        pieces: "Provided",
        last_confirmed_seen: "2022",
        kids_only: false,
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/kjhft99gHNLEJNkd7" },
            { iconType: "website", url: "https://www.ivystationculvercity.com/" },
            { iconType: "facebook", url: "https://www.facebook.com/ivystationculvercity/" },
        ],
        images: [
            {
                "id": "IMG_6267",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ivy_station_game_cart/IMG_6267.jpg"
            },
            {
                "id": "IMG_6270",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ivy_station_game_cart/IMG_6270.jpg"
            },
            {
                "id": "IMG_6269",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ivy_station_game_cart/IMG_6269.jpg"
            },
            {
                "id": "IMG_6273",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ivy_station_game_cart/IMG_6273.jpg"
            },
            {
                "id": "IMG_6263",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ivy_station_game_cart/IMG_6263.jpg"
            },
        ]
    },
    "sm_third_street_promenade": {
        id: "sm_third_street_promenade",
        name: "3rd St Promenade",
        photo_thumbnail: `${baseURL}/thumbnail_sm_third_street_promenade.jpg`,
        position: { lat: 34.016337, lng: -118.496906 },
        boardType: "oversize",
        neighborhood: "Santa Monica",
        day: "Daily",
        notes: `Portable all-weather oversize board and pieces available for public play on the 
        Santa Monica 3rd Street Promenade. White queen is partially broken but taped back together.
        Locals frequent the spot and may approach onlookers to play for money.`,
        purchase_required: false,
        pieces: "Provided.",
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/irBNUPxVPP2amqc79" },
        ],
        images: [
            {
                "id": "IMG_6257",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_third_street_promenade/IMG_6257.jpg"
            },
            {
                "id": "IMG_6261",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_third_street_promenade/IMG_6261.jpg"
            },
        ]
    },
    "fifth_street_dicks": {
        id: "fifth_street_dicks",
        name: "5th Street Dick's",
        photo_thumbnail: `${baseURL}/thumbnail_dicks.jpg`,
        position: { lat: 34.0058115, lng: -118.3339992 },
        boardType: "historic",
        neighborhood: "Leimert Park",
        day: "Closed",
        notes: `5th Street Dicks was a coffee and jazz house run by Richard Fulton, a man who rose up from addiction and 
        homelessness to become a pillar of the Leimert Park community. 5th Street Dicks had a row of heavy chess tables out front and was a hotspot for chess 
        players who would often play into the early hours of the morning. 
        Unfortunately, Richard has passed away and 5th Street Dicks has closed. A plaque in his honor can be found near the original location of the shop. 
        Learn more about 5th Street Dick's from 
        <a href=" https://cguprojects.tripod.com/fulton.html" target="_blank" rel="noopener noreferrer">his interview</a>, 
        <a href="https://www.kcet.org/shows/departures/a-coffee-company-called-5th-street-dicks" target="_blank" rel="noopener noreferrer">KCET</a>, 
        <a href="https://www.latimes.com/archives/la-xpm-1992-11-01-ci-1946-story.html" target="_blank" rel="noopener noreferrer">the LA Times</a>, 
        or the 
        <a href="http://www.leimertparkmovie.com/Biographies/RichardFulton.htm" target="_blank" rel="noopener noreferrer">Leimert Park movie</a>.`,
        purchase_required: true,
        pieces: `Provided`,
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/Tbyedyi2FuWZp8VX8",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/Tbyedyi2FuWZp8VX8" },
            { iconType: "facebook", url: "https://www.facebook.com/5THStreetDICKs/" },
        ],
        images: [
            {
                "id": "dicks",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/fifth_street_dicks/dicks.jpg"
            },
        ]
    },
    "harvey_easton_cafe": {
        id: "harvey_easton_cafe",
        name: "Harvey Easton Cafe",
        photo_thumbnail: `${baseURL}/thumbnail_harvey_easton_cafe.jpg`,
        position: { lat: 34.0762424965405, lng: -118.3649143515959 },
        boardType: "board",
        notes: `Single folding wooden board with all pieces intact. Often stored in the back sitting room on a 
        cabinet between two chairs. Bonus chessboard table in the bathroom; no pieces provided.`,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://g.page/harveyeastoncafe?share" },
            { iconType: "website", url: "https://www.eastongymco.com/harveys-cafe.php" },
            { iconType: "instagram", url: "https://www.instagram.com/harveyeastoncafe" },
            { iconType: "facebook", url: "https://www.facebook.com/harveyeastoncafe/" },
        ],
        images: [
            {
                "id": "IMG_6635",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6635.jpg"
            },
            {
                "id": "IMG_6636",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6636.jpg"
            },
            {
                "id": "IMG_6611",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6611.jpg"
            },
            {
                "id": "IMG_6620",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6620.jpg"
            },
            {
                "id": "IMG_6622",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6622.jpg"
            },
            {
                "id": "IMG_6618",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6618.jpg"
            },
            {
                "id": "IMG_6630",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6630.jpg"
            },
            {
                "id": "IMG_6614",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/harvey_easton_cafe/IMG_6614.jpg"
            },
            
        ]
    },
    "chess_palace": {
        id: "chess_palace",
        name: "ChessPalace",
        photo_thumbnail: `${baseURL}/thumbnail_chess_palace.jpg`,
        position: { lat: 33.78044603157567, lng: -118.04137083167234 },
        boardType: "store",
        notes: `Technically speaking, Chess Palace is in the OC and not LA. However it receives honorable mention for 
        being such a great resource for chess players. ChessPalace is a really special place, and I hope chess
        players continue to come and support this location for years to come. It is very rare to find a location
        that is completely dedicated to chess. ChessPalace hosts chess tournaments, sells chess supplies, hosts
        chess speakers, and offers a location for players to come, hang out, and play. It's a wonderful place
        and a MUST VISIT for any chess fan.`,
        purchase_required: false,
        pieces: "Some available.",
        last_confirmed_seen: "2022",
        neighborhood: "OC",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/f2x38FtyWeF8qAEXA" },
            { iconType: "website", url: "https://chesspalace.com/" },
        ],
        images: [
            {
                "id": "ChessPalace1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2810.jpg"
            },
            {
                "id": "ChessPalace2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2813.jpg"
            },
            {
                "id": "ChessPalace3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2804.jpg"
            },
            {
                "id": "ChessPalace4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2811.jpg"
            },
            {
                "id": "ChessPalace5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2808.jpg"
            },
            {
                "id": "ChessPalace6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2807.jpg"
            },
            {
                "id": "ChessPalace1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/chess_palace/IMG_2812.jpg"
            },
        ]
    },
    "knight_ware_inc": {
        id: "knight_ware_inc",
        name: "Knight Ware, Inc.",
        photo_thumbnail: `${baseURL}/thumbnail_knight_ware_inc.jpg`,
        position: { lat: 34.135020755525034, lng: -118.36149767706681 },
        boardType: "store",
        notes: `Classic game store within walking distance from the Universal City stop on the Red Line train. Minimal chess supplies for sale, 
        but many other games and figurines available.`,
        purchase_required: false,
        pieces: "Some for sale.",
        last_confirmed_seen: "2022",
        neighborhood: "Studio City",
        url: "",
        website: "",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/oakogCSoGhgoCpYB7" },
            { iconType: "website", url: "http://www.knight-ware.com/" },
            { iconType: "twitter", url: "https://twitter.com/knightwareinc?lang=en" },
        ],
        images: [
            {
                "id": "IMG_9528",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/knight_ware_inc/IMG_9528.jpg"
            },
            {
                "id": "IMG_9520",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/knight_ware_inc/IMG_9520.jpg"
            },
            {
                "id": "IMG_9529",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/knight_ware_inc/IMG_9529.jpg"
            },
            {
                "id": "IMG_9533",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/knight_ware_inc/IMG_9533.jpg"
            },
            {
                "id": "IMG_9534",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/knight_ware_inc/IMG_9534.jpg"
            },
            {
                "id": "IMG_9535",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/knight_ware_inc/IMG_9535.jpg"
            },
      
        ]
    },
    "macarthur_park": {
        id: "macarthur_park",
        name: "MacArthur Park",
        photo_thumbnail: `${baseURL}/thumbnail_macarthur_park.jpg`,
        position: { lat: 34.059095114380995, lng: -118.2776250580532 },
        boardType: "historic",
        neighborhood: "Westlake",
        day: "Closed",
        notes: `MacArthur Park was once a thriving hub for chess in Los Angeles. However, in an effort to combat issues with crime and extortion in the area, 
        the city decided in 2009 to remove the tables from the park. The LA Times has a 
        <a href="https://www.latimes.com/archives/la-xpm-2009-apr-24-me-outthere24-story.html" target="_blank" rel="noopener noreferrer">story</a> 
        covering this time period, and notes that there are efforts to bring the tables back. As of this writing in 2022, the tables have still not 
        returned.
        <br/>
        <br/>
        Sonia Romero, a local artist from Echo Park, has immortalized the chess players in her piece 
        <i>URBAN OASIS: MacArthur Park Westlake Metro Station (2010)</i>, which can be seen permanently installed in the  
        <a href="https://www.soniaromero.net/macarthur-park-westlake-metro-station" target="_blank" rel="noopener noreferrer">MacArthur Park / Westlake Metro station</a> 
        on the Red/Purple Line trains.
        <br/>
        <br/>
        In the southeast corner of the park, there is a sculpture titled <i>Clocktower</i> by George Herms, which was built in 1987. There are local rumors that this 
        art piece is an homage to the chess players, however there does not appear to be evidence to support this claim. Another LA Times 
        <a href="https://www.latimes.com/archives/la-xpm-2009-apr-24-me-outthere24-story.html" target="_blank" rel="noopener noreferrer">article</a>, this one 
        covering the art piece, makes no mention of chess, and simply states that the piece is meant to be a symbol of the passing of time and changes coming to the park. 
        <br/> 
        <br/> 
        The Los Angeles Public Library has two photos in their collection of men playing chess at MacArthur Park during the peak of its popularity:  
        <i><a href="https://tessa.lapl.org/cdm/singleitem/collection/photos/id/27620/rec/41" target="_blank" rel="noopener noreferrer">Chess tables at MacArthur Park</a></i>, and 
        <i><a href="https://tessa.lapl.org/cdm/singleitem/collection/photos/id/27620" target="_blank" rel="noopener noreferrer">Playing chess at MacArthur Park</a></i>.`,
        pieces: `Not Provided.`,
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/T4sVbvK4pVURWmrv7" },
            { iconType: "website", url: "https://www.laparks.org/reccenter/macarthur" },
        ],
        images: [
            {
                "id": "IMG_0001",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/macarthur_park/IMG_0001.jpg"
            },
            {
                "id": "IMG_9966",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/macarthur_park/IMG_9966.jpg"
            },
            {
                "id": "IMG_9968",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/macarthur_park/IMG_9968.jpg"
            },
            {
                "id": "IMG_9986",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/macarthur_park/IMG_9986.jpg"
            },
            {
                "id": "IMG_0145",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/macarthur_park/IMG_0145.jpg"
            },
            {
                "id": "IMG_0148",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/macarthur_park/IMG_0148.jpg"
            },
        ]
    },
    "odyssey_games": {
        id: "odyssey_games",
        name: "Odyssey Games",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.1462523, lng: -118.1155995 },
        boardType: "group",
        neighborhood: "Pasadena",
        day: "Saturday @ 6pm?",
        notes: `All-levels chess meetup with $5 entry fee. Every first Saturday of the month at 6:00pm.`,
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/YX1iEdV13KBEFoCf6" },
            { iconType: "website", url: "https://odysseygamesco.com/" },
            { iconType: "meetup", url: "https://www.meetup.com/odyssey-games/events/" },
            { iconType: "facebook", url: "https://www.facebook.com/OdysseyGamesPasadena/" },
        ],
    },
    "bar_tonys_saloon": {
        id: "bar_tonys_saloon",
        name: "Tony's Saloon",
        photo_thumbnail: `${baseURL}/thumbnail_bar_tonys_saloon.jpg`,
        position: { lat: 34.03474192843802, lng: -118.2316408154342 },
        boardType: "board",
        neighborhood: "Arts District",
        notes: `Tony's is a dive bar between skid row and the LA River. The back patio has two small wooden 
        chessboards available for patrons. While there are only two boards, there are three sets of pieces 
        provided; two wooden and one plastic. Their  
        <a href="https://www.instagram.com/p/Ca53Uyzlu8K/" target="_blank" rel="noopener noreferrer">instagram</a> 
        shows evidence of a chess table as well, though this table was not available on the Monday afternoon our photos 
        were taken.
        `,
        purchase_required: true,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/PD9h9JeVdjToEKyv8" },
            { iconType: "website", url: "https://www.tonyssaloon.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/tonyssaloon/?hl=en" },
        ],
        images: [
            {
                "id": "Tonys1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_tonys_saloon/IMG_2031.jpg"
            },
            {
                "id": "Tonys2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_tonys_saloon/IMG_2033.jpg"
            },
            {
                "id": "Tonys4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_tonys_saloon/IMG_2036.jpg"
            },
            {
                "id": "Tonys5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_tonys_saloon/IMG_2037.jpg"
            },
            {
                "id": "Tonys6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_tonys_saloon/IMG_2038.jpg"
            },
            {
                "id": "Tonys7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_tonys_saloon/IMG_2039.jpg"
            },
        ]
    },
    "starbucks_magic_johnson": { 
        id: "starbucks_magic_johnson",
        name: "Magic Johnson Starbucks",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 33.97755149717925, lng: -118.37197403579212 },
        boardType: "historic",
        neighborhood: "Ladera Heights",
        day: "Daily @ 5pm?",
        notes: `The chess community at the Ladera Heights (AKA 
        "<a href="https://archive.nytimes.com/www.nytimes.com/library/magazine/home/20001210mag-magicjohnson.html?Src=longreads" target="_blank">Magic Johnson</a>") Starbucks originated at 
        <a href="https://www.chess-la.com/chess-site/fifth_street_dicks" target="_blank">5th Street Dicks</a>. 
        Unfortunately, <a href="https://www.lataco.com/magic-johnson-starbucks-says-farewell/" target="_blank">this location closed</a> 
        in 2018, which forced the chess community to move again.
        `,
        purchase_required: true,
        verified: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2018",
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/1XtY35onyWb6G2Sn8" },
        ],
    },
    
    "angel_city_brewery": {
        id: "angel_city_brewery",
        name: "Angel City Brewery",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0465717, lng: -118.2382632 },
        boardType: "board",
        notes: "Unknown.",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2017",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://g.page/angelcitybeer?share" },
            { iconType: "website", url: "https://angelcitybrewery.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/angelcitybeer/?hl=en" },
            { iconType: "twitter", url: "https://twitter.com/angelcitybeer?lang=en" },
        ],
    },
    "tangs_donuts_sunset": {
        id: "tangs_donuts_sunset",
        name: "Tang's Donuts",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.095607468497306, lng: -118.28358569795552 },
        boardType: "historic",
        day: "Unknown",
        notes: `Tang's Donuts was a 24/7 donut shop is a historic location for chess in Los Angeles, which closed in 2014. 
        Before closing, it was known for attracting both chess enthusiasts as well as hardcore cyclists. 
        <a href="https://la.streetsblog.org/2013/03/15/wolfpack-hustle-prepares-to-crash-the-l-a-marathon-this-sunday/"
        target="_blank">Wolfpack Hustle</a> met at Tang's every Monday evening, and chess tournaments often 
        <a href="https://findingdonutspots.wordpress.com/2012/06/24/donuts-and-chess-at-tangs/"
        target="_blank">lasted all night</a>. Some games were played for free, though the location was 
        <a href="https://www.chessmaniac.com/Clubs/2006/02/play-chess-at-tangs-donut-shop.html"
        target="_blank">a known hotspot</a> for gambling and chess hustling. Strangely, Tang's was known to attract  
        <a href="http://www.losjeremy.com/blog/archives/2000/02/late_night_at_t.html"
        target="_blank">very strong players</a>, and was a hidden gem for solid games of chess.
        A 2011 game recorded by Aaron Schrank is available to watch on   
        <a href="https://www.youtube.com/watch?v=8OuA8k1dHlI"
        target="_blank">YouTube</a>.
        <br />
        "At the intersection of Sunset Boulevard and Fountain Avenue in Hollywood, a couple of miles west 
        of downtown Los Angeles along Sunset. A donut shop that is a hangout for chess and backgammon players 
        (they also play cards there), this is chiefly a place for gambling (for small to medium stakes unless 
        you are lucky enough to gain entre into the perennial "high roller" game, which has been going on 
        for sporadically for at least 15 years to my knowledge) but it is still possible to get free games 
        here. There are normally a few players there at any time after dark 7 days a week, but the peak 
        hours are between 9 p.m. and dawn the next day. Somewhat of a hangout for local Filipino players, 
        there are usually one or two masters there in the evenings willing and able to take your money. 
        (This used to be a much better place to play and spectate, with the likes of Cris Ramyrat and 
        other extremely strong blitz players showing up on a semi-regular basis, but seems to have 
        gone into a bit of a decline in recent years.)" - <a href="http://www.chessninja.com/dailydirt/2004/11/places-to-play-chess.htm#comment-1616"
        target="_blank">Geof Strayer, 2004</a>
        `,
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2014",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/Q7H1rZ8K7b1mwB4V9" },
            { iconType: "chesscom", url: "https://www.chess.com/blog/Zugzwang/tangs-donuts" },
        ],
    },
    "zanders_game_house": {
        id: "zanders_game_house",
        name: "Zanders Game House",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.21624056356501, lng: -119.03943521649009 },
        boardType: "group",
        notes: "",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://g.page/Zandersgamehouse?share" },
        ],
    },
    "sm_coffee_bean_main": {
        id: "sm_coffee_bean_main",
        name: "Coffee Bean on Ashland/Main, Santa Monica",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 33.999540473922046, lng: -118.48104060852734 },
        boardType: "historic",
        notes: `A once popular spot for chess players. Players would spend the day at the Santa Monica 
        Chess Park by the beach and would 
        <a href="https://www.smdp.com/chess-community-reacts-to-chess-park-dismantling/213839" target="_blank">
        relocate to Coffee Bean</a> to continue playing into the night.
        Unfortunately, this location has since closed.`,
        purchase_required: true,
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/qvzLY54GreWWyjFh7" },
        ],
    },

    "ninety_west_lounge": {
        id: "ninety_west_lounge",
        name: "90 West Lounge",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 33.9852861, lng: -118.4268955 },
        boardType: "board",
        notes: "Unknown.",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2022",
        socials: [
            { iconType: "gmap", url: "https://g.page/90westlounge?share" },
        ],
    },
    "crumbs_and_whiskers": {
        id: "crumbs_and_whiskers",
        name: "Crumbs & Whiskers | Kitten & Cat Cafe",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0835844, lng: -118.3645901 },
        boardType: "board",
        notes: "Unknown.",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2017",
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/eV4A1ffXAaaaEiS1A" },

        ],
    },
    "weird_wave_coffee": {
        id: "weird_wave_coffee",
        name: "Weird Wave Coffee Brewers",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0466877, lng: -118.2092804 },
        boardType: "board",
        notes: "Tournament board and clock available for play.",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2018",
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/AmSUiarqQpDadiLD7" },

        ],
    },
    "lindberg_park": {
        id: "lindberg_park",
        name: "Lindberg Park",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0032257, lng: -118.4004251 },
        boardType: "group",
        day: "Unknown",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/JiZXApaExk3YfUH1A",
        socials: [],
    },
    "starbucks_hollywood_western": {
        id: "starbucks_hollywood_western",
        name: "Starbucks, Hollywood & Western",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.101911, lng: -118.3111467 },
        boardType: "group",
        day: "Unknown",
        notes: "Unverified",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/vywkjuzvYgWLgzjV6",
        socials: [],
    },
    "starbucks_gower_sunset": {
        id: "starbucks_gower_sunset",
        name: "Starbucks, Gower & Sunset",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0978221, lng: -118.3247997 },
        boardType: "group",
        day: "Unknown",
        notes: "Unverified",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/rpVSGMP3JQNDWEvx5",
        socials: [],
    },
  
    
    "victory_park": {
        id: "victory_park",
        name: "Victory Park",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.1606057, lng: -118.0984911 },
        boardType: "tournament",
        neighborhood: "Pasadena",
        day: "Unknown",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/z3wqFUnYSdS8W16SA",
        socials: [],
    },
        // {
        //     id: "ricks_burgers",
        //     name: "Rick's Burgers",
        //     photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        //     position: { lat: 34.023150, lng: -118.496247 }, // TODO fix
        //     boardType: "group",
        //     neighborhood: "Alhambra",
        //     day: "Unknown",
        //     notes: "Unverified",
        //     purchase_required: true,
        //     pieces: "Unknown.",
        //     last_confirmed_seen: "Unknown"
        // },
    "veterans_park": {
        id: "veterans_park",
        name: "Veterans' Memorial Park",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0122069, lng: -118.4066396 },
        boardType: "group",
        neighborhood: "Culver City",
        day: "Saturday?",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/8oiGMP3GaYF4LY2VA",
        socials: [],
    },
    "occidental_college": {
        id: "occidental_college",
        name: "Occidental College",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.12736, lng: -118.2126664 },
        boardType: "group",
        neighborhood: "Unknown",
        day: "Thursdays @ 5pm?",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/uiHnt9MekW4ddWfw9",
        socials: [],
    },
    
    "valley_village_park": {
        id: "valley_village_park",
        name: "Valley Village Park",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.1611201, lng: -118.3863168 },
        boardType: "group",
        neighborhood: "NoHo",
        day: "Unknown",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/XmLCvLVj2wBNWzMd9",
        socials: [],
    },
    "carls_jr_noho": {
        id: "carls_jr_noho",
        name: "Carls Jr.",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.1832574, lng: -118.3984396 },
        boardType: "group",
        neighborhood: "NoHo",
        day: "Tuesdays",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/dmR3dC1JDUsHVNeEA",
        socials: [],
    },
    "cahuenga_branch_library": {
        id: "cahuenga_branch_library",
        name: "Cahuenga Library",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0910405, lng: -118.2911487 },
        boardType: "group",
        neighborhood: "Cahuenga",
        day: "Thursday @ 5pm?",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/2FAiwiQeaXK5ecyo8",
        socials: [],
    },
    "starbucks_encino": {
        id: "starbucks_encino",
        name: "Starbucks Ventura & Hayvenhurst",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.1578646, lng: -118.4939224 },
        boardType: "group",
        neighborhood: "Encino",
        day: "Fri & Sat @ 6pm?",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/rHNsb8ZEvsjhtpMP6",
        socials: [],
    },
    "mccambridge_park": {
        id: "mccambridge_park",
        name: "McCambridge Park Encino",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.1920068, lng: -118.3228609 },
        boardType: "group",
        neighborhood: "Burbank",
        day: "Fri @ 5pm?",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/GG1bHxbXm8bdymtR6",
        socials: [],
    },
    "club_thousand_oaks": {
        id: "club_thousand_oaks",
        name: "Thousand Oaks Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.2008332, lng: -118.8906685 },
        boardType: "club",
        neighborhood: "Thousand Oaks",
        day: "",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/GwceThmXb9gAz9iN7",
        website: "http://tochessclub.org/",
        socials: [],
    },
    // {
    //     id: "sgvcc_open",
    //     name: "SGVCC Open",
    //     photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
    //     position: { lat: 34.190574, lng: -118.1327217 },
    //     boardType: "tournament",
    //     neighborhood: "Altadena",
    //     day: "",
    //     notes: "Unverified",
    //     purchase_required: false,
    //     pieces: "Unknown.",
    //     last_confirmed_seen: "Unknown",
    //     url: "https://goo.gl/maps/xA9KoTNjLSY6QKFi6",
    //     website: "https://caissachess.net/online-registration/index/1534"
    // },
    
    "whole_foods_international_chess_club": {
        id: "whole_foods_international_chess_club",
        name: "Whole Foods International Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0011613, lng: -118.47207 },
        boardType: "group",
        neighborhood: "Venice",
        day: "Saturdays 7:30pm - 9:30pm",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/dffwPcC8teiBWz1C8",
        website: "http://wheretoplaychess.info/California/A7500639",
        kids_only: false,
        socials: [],
    },
    "playa_vista_chess_club": {
        id: "playa_vista_chess_club",
        name: "Playa Vista Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 33.9702345, lng: -118.4277389 },
        boardType: "club",
        neighborhood: "Playa Vista",
        day: "Wednesdays, 4:15PM-5:15PM",
        notes: "Unverified",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        url: "https://goo.gl/maps/vAyM3LjdnsYecWvd8",
        website: "https://chess4successla.com/",
        kids_only: true,
        socials: [],
    },

};