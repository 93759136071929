let baseURL = 'https://chess-la.s3.us-west-1.amazonaws.com/thumbnails';

export const world = {
    "az_tucson_hotel_mccoy": {
        id: "az_tucson_hotel_mccoy",
        name: "Hotel McCoy",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_mccoy/IMG_0313.jpg`,
        position: { lat: 32.19975, lng: -110.9831193 },
        boardType: "board",
        notes: `Board available with pieces in a stack of games in the hotel lobby. Intended use for guests of the hotel only.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Tucson",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/GMDtujzWVoRxyRLu8" },
            { iconType: "website", url: "https://hotelmccoy.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/hotelmccoy/" },
            { iconType: "youtube", url: "https://www.youtube.com/channel/UC30C7TMAk7CR0xAMS0beOxA" },
            { iconType: "facebook", url: "https://www.facebook.com/hotelmccoy/" },
        ],
        images: [
            {
                "id": "IMG_0313",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_mccoy/IMG_0313.jpg"
            },
            {
                "id": "IMG_0254",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_mccoy/IMG_0254.jpg"
            },
            {
                "id": "IMG_0252",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_mccoy/IMG_0252.jpg"
            },
            {
                "id": "IMG_0253",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_mccoy/IMG_0253.jpg"
            },
        ]
    },
    "az_tucson_hotel_congress": {
        id: "az_tucson_hotel_congress",
        name: "Hotel Congress",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_congress/IMG_0264.jpg`,
        position: { lat: 32.222248, lng: -110.9672917 },
        boardType: "table",
        notes: `One table with two boards available in the hotel lobby. Ask an employee for access to the pieces. The hotel 
        lobby is also an entrance to the bar and cafe, both of which are open to the public.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Tucson",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/Czu6K6RojLLjKWF38" },
            { iconType: "website", url: "https://hotelcongress.com/" },
            { iconType: "twitter", url: "https://www.twitter.com/hotelcongress" },
            { iconType: "instagram", url: "https://www.instagram.com/hotelcongress/" },
            { iconType: "facebook", url: "https://www.facebook.com/hotelcongress" },
        ],
        images: [
            {
                "id": "IMG_0264",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_congress/IMG_0264.jpg"
            },
            {
                "id": "IMG_0263",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_hotel_congress/IMG_0263.jpg"
            },
        ]
    },
    "az_brisbee_brewing_co": {
        id: "az_brisbee_brewing_co",
        name: "Old Brisbee Brewing Co.",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0633.jpg`,
        position: { lat: 31.4422951, lng: -109.916632 },
        boardType: "board",
        notes: `Two cardboard folding boards and a tub of miscellaneous pieces.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Bisbee",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/5HyhwN5pBV12uUz98" },
            { iconType: "website", url: "http://www.oldbisbeebrewingcompany.com/" },
            { iconType: "instagram", url: "http://instagram.com/oldbisbeebrewing" },
            { iconType: "facebook", url: "http://www.facebook.com/bisbeebeer" },
        ],
        images: [
            {
                "id": "IMG_0641",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0641.jpg"
            },
            {
                "id": "IMG_0633",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0633.jpg"
            },
            {
                "id": "IMG_0637",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0637.jpg"
            },
            {
                "id": "IMG_0639",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0639.jpg"
            },
            
            {
                "id": "IMG_0670",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0670.jpg"
            },
            {
                "id": "IMG_0672",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_brewing_co/IMG_0672.jpg"
            },
        ]
    },
    "az_brisbee_city_park": {
        id: "az_brisbee_city_park",
        name: "Bisbee Old City Park",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_city_park/IMG_0578.jpg`,
        position: { lat: 31.4438757, lng: -109.9169065 },
        boardType: "oversize",
        notes: `Oversize board painted onto the ground in the park. Pieces not readily available. Possibly brought out for special events.
        `,
        purchase_required: true,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Bisbee",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/u17BngwUS2iN4qB57" },
        ],
        images: [
            {
                "id": "IMG_0578",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_city_park/IMG_0578.jpg"
            },
            {
                "id": "IMG_0579",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_city_park/IMG_0579.jpg"
            },
            {
                "id": "IMG_0580",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_city_park/IMG_0580.jpg"
            },
            {
                "id": "IMG_0582",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_city_park/IMG_0582.jpg"
            },
        ]
    },
    "az_brisbee_historical_museum": {
        id: "az_brisbee_historical_museum",
        name: "Bisbee Mining & Historical Museum",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_historical_museum/IMG_0590.jpg`,
        position: { lat: 31.442162, lng: -109.914534 },
        boardType: "table",
        notes: `Two concrete chess tables with tile inlays in the park on the west side of the museum.
        `,
        purchase_required: true,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Bisbee",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/6n9CoboiZJGxrjqf7" },
            { iconType: "website", url: "https://bisbeemuseum.org/" },
            { iconType: "facebook", url: "https://www.facebook.com/BisbeeMuseum" },
        ],
        images: [
            {
                "id": "IMG_0590",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_historical_museum/IMG_0590.jpg"
            },
            
            {
                "id": "IMG_0593",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_historical_museum/IMG_0593.jpg"
            },
            {
                "id": "IMG_0594",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_historical_museum/IMG_0594.jpg"
            },
            {
                "id": "IMG_0601",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_historical_museum/IMG_0601.jpg"
            },
            {
                "id": "IMG_0604",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_historical_museum/IMG_0604.jpg"
            },
        ]
    },
    "az_brisbee_social_club": {
        id: "az_brisbee_social_club",
        name: "Bisbee Social Club",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0691.jpg`,
        position: { lat: 31.4414444, lng: -109.9198469 },
        boardType: "board",
        notes: `Two folding wooden boards and three rolled up tournament sets with clocks. One wooden board is missing some pieces.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Bisbee",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/F6fpW54K58PUNwodA" },
            { iconType: "website", url: "https://www.bisbeesocialclub.com/" },
            // { iconType: "yelp", url: "https://www.yelp.com/biz/bisbee-social-club-bisbee" },
            { iconType: "instagram", url: "https://www.instagram.com/bisbeesocialclub/?hl=en" },
        ],
        images: [
            {
                "id": "IMG_0730",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0730.jpg"
            },
            {
                "id": "IMG_0732",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0732.jpg"
            },
            {
                "id": "IMG_0691",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0691.jpg"
            },
            {
                "id": "IMG_0704",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0704.jpg"
            },
            {
                "id": "IMG_0718",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0718.jpg"
            },
            {
                "id": "IMG_0725",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_brisbee_social_club/IMG_0725.jpg"
            },
        ]
    },
    "az_tucson_casa_video": {
        id: "az_tucson_casa_video",
        name: "Casa Video",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0476.jpg`,
        position: { lat: 32.2364675, lng: -110.9319156 },
        boardType: "board",
        notes: `Multiple wooden boards available for casual play on a shelf of assorted board games. Part of a bar and video store.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Tucson",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/sdudP54XdND8ww748" },
            { iconType: "website", url: "http://www.casafilmbar.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/casafilmbar/?hl=en" },
            { iconType: "facebook", url: "https://www.facebook.com/Casa-Video-and-Casa-Film-Bar-70401414776/" },
        ],
        images: [
            {
                "id": "IMG_0476",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0476.jpg"
            },
            {
                "id": "IMG_0473",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0473.jpg"
            },
            {
                "id": "IMG_0466",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0466.jpg"
            },
            {
                "id": "IMG_0461",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0461.jpg"
            },
            {
                "id": "IMG_0464",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0464.jpg"
            },
            {
                "id": "IMG_0465",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0465.jpg"
            },
            
            {
                "id": "IMG_0468",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0468.jpg"
            },
            
            {
                "id": "IMG_0474",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0474.jpg"
            },
            {
                "id": "IMG_0475",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_tucson_casa_video/IMG_0475.jpg"
            },
            
        ]
    },
    "az_mesa_jarrods": {
        id: "az_mesa_jarrods",
        name: "Jarrod's Coffee, Tea, & Gallery",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_mesa_jarrods/IMG_0955.jpg`,
        position: { lat: 33.4153726, lng: -111.8386131 },
        boardType: "board",
        notes: `Multiple wooden boards available for casual play on a shelf of assorted board games inside of the coffee shop.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Mesa",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/miKeKduci9Uw6ucj7" },
            { iconType: "website", url: "https://jarrodscoffeeteaandgallery.com/" },
            { iconType: "twitter", url: "https://twitter.com/JarrodsCoffeeTG" },
            { iconType: "instagram", url: "https://www.instagram.com/jarrodscoffeeteaandgallery/" },
            { iconType: "facebook", url: "https://www.facebook.com/Jarrodsartcoffeeteagallery/" },
        ],
        images: [
            {
                "id": "IMG_0955",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_mesa_jarrods/IMG_0955.jpg"
            },
            {
                "id": "IMG_0964",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_mesa_jarrods/IMG_0964.jpg"
            },
            {
                "id": "IMG_0963",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_mesa_jarrods/IMG_0963.jpg"
            },
            {
                "id": "IMG_0967",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_mesa_jarrods/IMG_0967.jpg"
            },
            {
                "id": "IMG_0968",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_mesa_jarrods/IMG_0968.jpg"
            },
        ]
    },
    "az_phoenix_blue_buddha": {
        id: "az_phoenix_blue_buddha",
        name: "Blue Buddha Collective",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1046.jpg`,
        position: { lat: 33.4532214, lng: -111.9285313 },
        boardType: "table",
        notes: `Chess table with pieces available for coffee shop customers.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Scottsdale",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/y3JQ8yu1agb1NjoR7" },
            { iconType: "website", url: "https://ilovebluebuddha.com/" },
        ],
        images: [
            {
                "id": "IMG_1046",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1046.jpg"
            },
            {
                "id": "IMG_1029",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1029.jpg"
            },
            {
                "id": "IMG_1031",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1031.jpg"
            },
            {
                "id": "IMG_1028",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1028.jpg"
            },
            {
                "id": "IMG_1051",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1051.jpg"
            },
            {
                "id": "IMG_1025",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1025.jpg"
            },
            {
                "id": "IMG_1036",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/az_phoenix_blue_buddha/IMG_1036.jpg"
            },
        ]
    },
    "ca_palm_springs_certel_coffee": {
        id: "ca_palm_springs_certel_coffee",
        name: "Cartel Roasting Co.",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_palm_springs_certel_coffee/IMG_1353.jpg`,
        position: { lat: 33.843911, lng: -116.5518341 },
        boardType: "board",
        notes: `One medium sized foldable wooden board available for customers to play.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2023",
        neighborhood: "Palm Springs",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/PQzYNmpBYRDVr3Rb9" },
            { iconType: "website", url: "https://cartelroasting.co/locations/" },
            { iconType: "twitter", url: "https://twitter.com/cartelroasting" },
            { iconType: "instagram", url: "https://www.instagram.com/cartelroastingco/" },
            { iconType: "facebook", url: "https://www.facebook.com/Cartelroastingco/" },
        ],
        images: [
            {
                "id": "IMG_1353",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_palm_springs_certel_coffee/IMG_1353.jpg"
            },
            {
                "id": "IMG_1357",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_palm_springs_certel_coffee/IMG_1357.jpg"
            },
            {
                "id": "IMG_1365",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_palm_springs_certel_coffee/IMG_1365.jpg"
            },
            {
                "id": "IMG_1367",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_palm_springs_certel_coffee/IMG_1367.jpg"
            },
        ]
    },
    "or_hillsboro_insomnia_coffee": {
        id: "or_hillsboro_insomnia_coffee",
        name: "Insomnia Coffee Co - Baseline",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6987.jpg`,
        position: { lat: 45.5202361, lng: -122.9340699 },
        boardType: "board",
        notes: `Homey chain coffeeshop with a livingroom vibe. Several chess boards and pieces available for customers to play 
        on a shelf towards the back of the shop, near the restrooms. Likely enough boards available to host a small tournament.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Hillsboro",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/PQzYNmpBYRDVr3Rb9" },
            { iconType: "website", url: "https://insomniacoffee.co/" },
            { iconType: "instagram", url: "https://www.instagram.com/insomniacoffee/" },
            { iconType: "facebook", url: "https://www.facebook.com/insomniacoffee" },
        ],
        images: [
            {
                "id": "IMG_6987",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6987.jpg"
            },
            {
                "id": "IMG_6989",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6989.jpg"
            },
            {
                "id": "IMG_6993",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6993.jpg"
            },
            {
                "id": "IMG_6994",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6994.jpg"
            },
            {
                "id": "IMG_6995",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6995.jpg"
            },
            {
                "id": "IMG_6996",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6996.jpg"
            },
            {
                "id": "IMG_6997",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6997.jpg"
            },
            {
                "id": "IMG_6999",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_6999.jpg"
            },
            {
                "id": "IMG_7001",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_insomnia_coffee/IMG_7001.jpg"
            },
        ]
    },
    "or_hillsboro_orenco_learning": {
        id: "or_hillsboro_orenco_learning",
        name: "Orenco Learning Club",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_orenco_learning/IMG_7118.jpg`,
        position: { lat: 45.5315396, lng: -122.9185706 },
        boardType: "club",
        notes: `Weekly game nights both in person and online. See meetup for schedule.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Orenco",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/SFQ7mX1KyJKm25EA9" },
            { iconType: "website", url: "https://www.orencolearningclub.com/" },
            { iconType: "meetup", url: "https://www.meetup.com/orenco-learning-club/" },
        ],
        images: [
            {
                "id": "IMG_7118",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/or_hillsboro_orenco_learning/IMG_7118.jpg"
            },
        ]
    },
    "nm_santa_fe_remix_bar": {
        id: "nm_santa_fe_remix_bar",
        name: "Remix Audio Bar",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/nm_santa_fe_remix_bar/IMG_7824.jpg`,
        position: { lat: 35.6153358, lng: -105.9823987, },
        boardType: "board",
        notes: `Small bar with vintage audio decor. Two chess boards available for customers.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Santa Fe",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/CLT9JjbiBH6RnvRu5" },
            { iconType: "website", url: "https://www.rmxaudiobar.com/" },
            { iconType: "youtube", url: "https://www.youtube.com/channel/UCs0QdFbg_bb47StlqSz2WFg" },
            { iconType: "instagram", url: "https://www.instagram.com/rmxab" },
            { iconType: "facebook", url: "https://www.facebook.com/rmxab" },
        ],
        images: [
            {
                "id": "IMG_7824",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/nm_santa_fe_remix_bar/IMG_7824.jpg"
            },
            {
                "id": "IMG_7828",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/nm_santa_fe_remix_bar/IMG_7828.jpg"
            },
        ]
    },
    "ca_pine_grove_roadhouse_coffee": {
        id: "ca_pine_grove_roadhouse_coffee",
        name: "Pine Grove Roadhouse Coffee",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_pine_grove_roadhouse_coffee/IMG_0986.jpg`,
        position: { lat: 38.4134247, lng: -120.7235863, },
        boardType: "board",
        notes: `Quaint coffee shop with a single chess board amongst other games for visitors to play.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Pine Grove",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/ripMULFuzbCMB4Q46" },
            { iconType: "website", url: "https://pgrcoffee.com/" },
        ],
        images: [
            {
                "id": "IMG_0986",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_pine_grove_roadhouse_coffee/IMG_0986.jpg"
            },
            {
                "id": "IMG_0983",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_pine_grove_roadhouse_coffee/IMG_0983.jpg"
            },
            {
                "id": "IMG_0987",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_pine_grove_roadhouse_coffee/IMG_0987.jpg"
            },
            {
                "id": "IMG_0988",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_pine_grove_roadhouse_coffee/IMG_0988.jpg"
            },
        ]
    },
    "ca_tahoe_rustic_cottages": {
        id: "ca_tahoe_rustic_cottages",
        name: "Tahoe Vista Lodge & Cabins",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_tahoe_rustic_cottages/IMG_1567.jpg`,
        position: { lat: 39.239244, lng: -120.041170 },
        boardType: "table",
        notes: `Cottage lodging along lake tahoe. Chess table available on the front deck near the lobby.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Tahoe Vista",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/BiM9vwuCaquTFRVz8" },
            { iconType: "website", url: "https://www.rusticcottages.com/" },
            { iconType: "facebook", url: "https://www.facebook.com/Rustic-Cottage-Resort-105678296165283/" },
        ],
        images: [
            {
                "id": "IMG_1572",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_tahoe_rustic_cottages/IMG_1572.jpg"
            },
            {
                "id": "IMG_1569",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_tahoe_rustic_cottages/IMG_1569.jpg"
            },
            {
                "id": "IMG_1567",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_tahoe_rustic_cottages/IMG_1567.jpg"
            },
            {
                "id": "IMG_1568",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_tahoe_rustic_cottages/IMG_1568.jpg"
            },
        ]
    },
    "ca_carpinteria_chess_club": {
        id: "ca_carpinteria_chess_club",
        name: "Carpinteria Chess Club",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carpinteria_chess_club/IMG_7664.jpg`,
        position: { lat: 34.3974403, lng: -119.5196194 },
        boardType: "club",
        notes: `Public chess club in the local community library.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Carpinteria",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/K6iSFb87io3zsXg27" },
            { iconType: "website", url: "https://carpinterialibrary.org/event/chess-club/2022-10-06/" },
        ],
        images: [
            {
                "id": "IMG_7664",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carpinteria_chess_club/IMG_7664.jpg"
            },
           
        ]
    },
    "ca_carlsbad_baba_coffee": {
        id: "ca_carlsbad_baba_coffee",
        name: "Baba Coffee",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carlsbad_baba_coffee/IMG_8230.jpg`,
        position: { lat: 33.1641328, lng: -117.3612812 },
        boardType: "board",
        notes: `Two small wooden chess boards available for customers.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Carlsbad",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/KEkemaMCabC8rad37" },
            { iconType: "website", url: "https://babacoffee.com/" },
        ],
        images: [
            {
                "id": "IMG_8230",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carlsbad_baba_coffee/IMG_8230.jpg"
            },
            {
                "id": "IMG_8232",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carlsbad_baba_coffee/IMG_8232.jpg"
            },
            {
                "id": "IMG_8233",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carlsbad_baba_coffee/IMG_8233.jpg"
            },
            {
                "id": "IMG_8235",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carlsbad_baba_coffee/IMG_8235.jpg"
            },
            {
                "id": "IMG_8242",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ca_carlsbad_baba_coffee/IMG_8242.jpg"
            },
        ]
    },
    "virgin_islands_franklin_park": {
        id: "virgin_islands_franklin_park",
        name: "Franklin D. Roosevelt Memorial Park",
        photo_thumbnail: `https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virgin_islands_franklin_park/IMG_3126.jpg`,
        position: { lat: 18.3419048, lng: -64.9306766 },
        boardType: "board",
        notes: `Two wooden chess tables with painted-on boards available to the public in the park.
        `,
        purchase_required: true,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Charlotte Amalie",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/giXv4FQ4h4K5V8pu7" },
        ],
        images: [
            {
                "id": "IMG_3126",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virgin_islands_franklin_park/IMG_3126.jpg"
            },
            {
                "id": "IMG_3125",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virgin_islands_franklin_park/IMG_3125.jpg"
            },
        ]
    },
}
