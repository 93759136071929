import React from "react";
import "./ChessSiteImageGallery.styles.css";

export default function ChessSiteImageGallery(props) {

    const images = props.images;

    return (
        <div className="classSiteImageContainer">
            {images.map(
                image => (
                    <div key={image.id} className="classSiteImage">
                        <img src={image.url} alt={image.id} onClick={event => props.changeHeaderImage(image.url)} />
                    </div>
                ))
            }
        </div>
    );
}
