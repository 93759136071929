import React, { useEffect, useState } from "react";
import ChessSiteCard from "./ChessSiteCard";
import Menu from "../menu/Menu";
import { clubs } from "../../constants/chessSites/clubs";
import { tables } from "../../constants/chessSites/tables";
import { staticPlaceInfo } from "../../constants/placeIds";
import { lessons } from "../../constants/chessSites/lessons";
import { world } from "../../constants/chessSites/world";

import "./ChessSiteCardGallery.styles.css";

export default function ChessSiteCardGallery(props) {
    const [chessSites, setChessSites] = useState({});

    useEffect(() => {
        if (Object.keys(chessSites).length === 0) {
            setChessSites({
                ...clubs,
                ...staticPlaceInfo,
                ...tables,
                ...lessons,
                ...world
            });
        }

    }, []);

    return (
        <>
            <Menu />
            <div className="chessSiteCardContainer">
                {Object.keys(chessSites).map(
                        name => {
                        return chessSites[name].verified ?
                            <ChessSiteCard key={chessSites[name].id} chessSite={chessSites[name]} />
                            :
                            <></>
                            }
                    )
                }
            </div>
        </>
    );
}
