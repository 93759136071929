let baseURL = 'https://chess-la.s3.us-west-1.amazonaws.com/thumbnails';

export const lessons = {
    "chess_for_success_la": {
        id: "chess_for_success_la",
        name: "Chess for Success LA",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 33.99535841645825, lng: -118.46420490809776 },
        boardType: "lessons",
        neighborhood: "Pasadena",
        notes: `Chess lessons for children and adults, privately and in small groups, in person and online. 
        Taught by Twice-Olympian Chess Player Ivona Jezierska.
        `,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "website", url: "https://chess4successla.com/" },
            { iconType: "twitter", url: "https://twitter.com/chess4successla?lang=en" },
            { iconType: "facebook", url: "https://www.facebook.com/Chess4Success/" },
            { iconType: "chesscom", url: "https://www.chess.com/club/chess4successla" },
        ],
    },
};