import React from "react";
import ChessMap from "../components/map/ChessMap";
import "./MapView.styles.css";

export default function MapView() {

  return (
    <div className="mapViewContainer">
      <div className="mapContainer">
        <ChessMap />
      </div>
    </div>
  );
}
