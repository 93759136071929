import React from "react";
import Menu from "../menu/Menu";
import "../common/Common.styles.css";


export default function Calendar(props) {

    return (
        <>
            <Menu />
            <div className="centeredColumnContainer">
                <p>The calendar below displays the usual dates, times and locations of recurring chess
                    events in Los Angeles and the surrounding area. Chess LA does not run or maintain any
                    of the groups or events listed. Information posted here is not frequently
                    updated and should be used only as a starting point. Check the websites of the
                    events themselves for the most up-to-date information. If the calendar does not
                    load, <a href="https://calendar.google.com/calendar/u/0/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America/Los_Angeles&showTitle=0&showNav=1&showCalendars=1&showPrint=0&src=NHZyYTk3NmFrazUxYmdrbjJocjJhMWx2bHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=bHNmNWZzcmRoZDllbTN0OWZhbXJoMzN1cGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cWU1YzdxcWNuNzhvZTJpZ3V2a20wYjRjNWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=b3VvZG5kZ3A0djhzaGNiaGdiaTlzN2xuY3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=N2ljNW84Y2Fwb24zNmdwbm9mc2ZkNWhpczRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=OWVwaWdwdXF0NGM3ZWQ0ZGJsdHZlcGQ0YWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=N2hqMmRlYjhvdDMzY2hqcmx0OWZzczNpOGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cXZiNmUwZWlndW4wcm81ZXNtcHF0aWVqMWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=MGRmOTRhajQ0ZWNvbjN2YmI3YTg4YWFqdW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cnZrdmxwZmNldTVpNjMydGRnbmxmdWcxb2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F4511E&color=%230B8043&color=%239E69AF&color=%23E4C441&color=%23D50000&color=%238E24AA&color=%239E69AF&color=%237CB342&color=%234285F4&color=%23C0CA33&pli=1" target="_blank" rel="noopener noreferrer">click here</a> to open it in a new window.
                </p>

                <iframe
                    title="Google Calendar of Chess in LA"
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showTitle=0&showNav=1&showCalendars=1&showPrint=0&src=NHZyYTk3NmFrazUxYmdrbjJocjJhMWx2bHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=bHNmNWZzcmRoZDllbTN0OWZhbXJoMzN1cGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cWU1YzdxcWNuNzhvZTJpZ3V2a20wYjRjNWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=b3VvZG5kZ3A0djhzaGNiaGdiaTlzN2xuY3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=N2ljNW84Y2Fwb24zNmdwbm9mc2ZkNWhpczRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=OWVwaWdwdXF0NGM3ZWQ0ZGJsdHZlcGQ0YWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=N2hqMmRlYjhvdDMzY2hqcmx0OWZzczNpOGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cXZiNmUwZWlndW4wcm81ZXNtcHF0aWVqMWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=MGRmOTRhajQ0ZWNvbjN2YmI3YTg4YWFqdW9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cnZrdmxwZmNldTVpNjMydGRnbmxmdWcxb2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F4511E&color=%230B8043&color=%239E69AF&color=%23E4C441&color=%23D50000&color=%238E24AA&color=%239E69AF&color=%237CB342&color=%234285F4&color=%23C0CA33"
                    // style="border:solid 1px #777"
                    width="800"
                    height="600"
                    frameborder="0"
                    scrolling="no"></iframe>
            </div>
        </>
    );
}
