import React, { useEffect, useState, useRef } from "react";
import "./ChessSiteCard.styles.css";
import ChessSiteImageGallery from "../chessSites/ChessSiteImageGallery";
import ChessSiteMap from "../chessSites/ChessSiteMap";
import ChessSiteStatIcon from "../chessSiteStats/ChessSiteStatIcon";

export default function ChessSiteCard(props) {
    const [imgUrl, setImgUrl] = useState(null);
    const [images, setImages] = useState([]);
    const headerImage = useRef(null);

    useEffect(() => {
        if (!imgUrl) {
            setImgUrl(props.chessSite.images ? props.chessSite.images[0].url :
                "https://chess-la.s3.us-west-1.amazonaws.com/header_images/chessboard_half_dark.jpg");
        }

        if (images.length === 0) {
            setImages(props.chessSite.images ? props.chessSite.images : []);
        }
    }, []);

    function changeHeaderImage(newImageUrl) {
        headerImage.current.src = newImageUrl;
    }

    return (
        <>
            <div className="container">
                <div className="chessSiteCard">
                    <div className="chessSiteCard__header">
                        <img ref={headerImage} src={imgUrl} alt="card__image" className="card__image" width="600" />
                    </div>
                    <div className="chessSiteCard__body">
                        <h4>{props.chessSite.name}</h4>

                        <ChessSiteImageGallery changeHeaderImage={changeHeaderImage} images={images} />
                        <ChessSiteStatIcon socials={ props.chessSite.socials } />
                        <h5>{props.chessSite.neighborhood}</h5>
                        <p dangerouslySetInnerHTML={{__html: props.chessSite.notes}}></p>

                        <div className="chessSiteContainer">
                            <ChessSiteMap chessSite={props.chessSite}/>
                        </div>
                    </div>
                    <div className="chessSiteCard__footer">
                        <div className="user">
                            <div className="user__info">
                            <small>Verified: {props.chessSite.last_confirmed_seen}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
