import React from "react";
import "./Menu.styles.css";
import { Link } from "react-router-dom";

export default function Menu() {
  return (
    <div className="menu_container">
      <div className="ui menu">
        <div >
          <Link to="/" className="item">Map</Link>   
          <Link to="/gallery" className="item">Gallery</Link>
          <Link to="/calendar" className="item">Calendar</Link>
          <Link to="/about" className="item">About</Link>
          <Link to="/thanks" className="item">Credits</Link>
        </div>
        </div>
    </div>
  );
}
