import React from "react";
import "./Legend.styles.css";
import { category_display_names } from "../../../constants/categories";

const Legend = () => {
  const iconBase = "https://chess-la.s3.us-west-1.amazonaws.com/icons/";
  const icons = [
    {
      name: category_display_names['board'],
      icon: iconBase + "chess-board-circle.png",
    },
    {
      name: category_display_names['oversize'],
      icon: iconBase + "chess-board-color.png",
    },
    {
      name: category_display_names['table'],
      icon: iconBase + "chess-table-circle.png",
    },
    {
      name: category_display_names['club'],
      icon: iconBase + "chess-club-circle.png",
    },
    {
      name: category_display_names['group'],
      icon: iconBase + "chess-game-circle.png",
    },
    {
      name: category_display_names['tournament'],
      icon: iconBase + "chess-tournament-circle.png",
    },
    {
      name: category_display_names['store'],
      icon: iconBase + "chess-store.png",
    },
    {
      name: category_display_names['lessons'],
      icon: iconBase + "chess-school.png",
    },
    {
      name: category_display_names['historic'],
      icon: iconBase + "chess-museum-circle.png",
    },
  ];

  return (
    <div id="legend">
      <h3>Legend</h3>
      {icons.map(
        item => (
          <div key={item.name} className="legend_item">
            <img src={item.icon} alt={item.name}/> {item.name}
          </div>
        ))}
    </div>
  )
}

export default Legend;