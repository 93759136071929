export const iconMap = {
    "board": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-board-circle.png",
    "oversize": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-board-color.png",
    "store": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-store.png",
    "table": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-table-circle.png",
    "group": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-game-circle.png",
    "club": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-club-circle.png",
    "tournament": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-tournament-circle.png",
    "historic": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-museum-circle.png",
    "lessons": "https://chess-la.s3.us-west-1.amazonaws.com/icons/chess-school.png",

};

export const cardIconMap = {
    "website": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/world-wide-web.png",
    "instagram": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/instagram.png",
    "twitter": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/twitter.png",
    "gmap": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/google-maps.png",
    "meetup": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/meetup.png",
    "facebook": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/facebook.png",
    "youtube": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/youtube.png",
    "tiktok": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/tik-tok.png",
    "chesscom": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/chesscomlogo.png",
    "lichess": "https://chess-la.s3.us-west-1.amazonaws.com/icons/card_icons/lichess.png",
};