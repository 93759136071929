export const category_display_names = {
    "board": "Board",
    "oversize": "Oversize Board",
    "store": "Store",
    "table": "Table",
    "group": "Pickup Games",
    "club": "Club",
    "tournament": "Tournaments",
    "historic": "Historic",
    "lessons": "Lessons"
};