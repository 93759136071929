import React from "react";
import Menu from "../menu/Menu";
import "../common/Common.styles.css";
import "./About.styles.css";

export default function About(props) {

    return (
        <>
            <Menu />
            <div className="centeredColumnContainer">
                <div>
                    <div>
                        <img className="avatar" src="https://chess-la.s3.us-west-1.amazonaws.com/avatars/avatar.png" alt="Avatar image of a girl leaning against a queen chess piece, saying Checkmate" />
                    </div>
                    <div>
                        <p>
                            I'm an Angeleno, a beginner chess player, and an avid chess culture enthusiast. It can be tough to 
                            find other players in the city sometimes! Many chess clubs have had to close their doors due to COVID-19, 
                            and many popular chess locations are undocumented and shared primarily via word of mouth.
                        </p>
                        <br />
                        <p>
                            I've spent the last few months researching locations to play chess in Los Angeles, and have 
                            taken public transit all over the city to verify these location in person. I've found active 
                            chess clubs, historic chess sites, chess hustlers, pickup games in parks and cafes, 
                            public chess tables, and more. 
                        </p>
                        <br />
                        <p>
                            I'm also a huge fan of Los Angeles' growing public transit system, and believe it doesn't deserve 
                            its bad reputation. Because of this, I've included information about how to reach each site by bus
                            or train. LA is a lot more fun when you don't have to worry about parking!
                        </p>
                        <br />
                        <p>
                            All content on this website was created by me unless otherwise stated. I took the photos myself in person, 
                            with the exception of historical sites that no longer exist. Photos from other sources are shown with credit to the photographer 
                            who gave permission to have their photo displayed on chess-la.com.
                        </p>
                        <br />
                        <p>
                            The information available on this website is also available on <a href="https://goo.gl/maps/16yNiS2MGcaSWjzX9" target="_blank" rel="noopener noreferrer">my Google Maps profile</a>, in hopes 
                            of making it easier for locals and visitors to find and play chess in the city. 
                        </p>
                        <br />
                        <p>
                            If you know of a location where chess can be played in the city that is not yet listed here, please reach out and let me know! 
                            Send a message to my gmail, findchessinla, with the information listed below:
                        </p>
                    </div>
                </div>
                <div>
                    <p>
                        <ul>
                            <li>Location Name: For clubs, this is the official club name. For boards, tables, pickup games,
                                etc., this is the name of the location where it resides, such as the park or cafe.</li>
                            <li>Category: Board, Oversize Board, Store, Table, Pickup Games, Club, Tournaments, Historic, or Lessons.</li>
                            <li>Photos: You must have taken the photos yourself, and must give permission for it to be posted online at chess-la.com.</li>
                            <li>Links to relevant web presence / social media, if available.</li>
                            <li>Nieghborhood Name (if in LA city itself) or City Name (if in LA county or the general region).</li>
                            <li>A short description of the location.</li>
                            <li>The year of verification, AKA the year that you last saw this site <i>in person</i>. This is very important,
                                as it keeps our list fresh and accurate.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    );
}
