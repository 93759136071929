import React, { useEffect, useState, useCallback } from "react";
import { iconMap } from "../../../constants/iconMap";

const Marker = (props) => {
  const [marker, setMarker] = useState();
  const [openInfoWindow, setOpenInfoWindow] = useState(false);

  const clickHandler = useCallback(
    (e) => {
      if (props.onClick) props.onClick(e);
      // setOpenInfoWindow(true);
    },
    [props.onClick]
  );

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker({
        icon: iconMap[props.boardType],
        shape: {
          coords: [0,0,200,200], 
          type: "rect"
        },
        map: props.map
      }));
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(props);
      marker.addListener('mousedown', function () {
        setOpenInfoWindow(true);
      });
    }
  }, [marker, props, clickHandler]);

  return (
    <>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            anchor: marker,
            open: openInfoWindow,
            onClose: (e) => {
              setOpenInfoWindow(false)
            },
            map: props.map,
          });
        }
      })}
    </>
  );
};
Marker.displayName = "Marker";
export default Marker;
