let baseURL = 'https://chess-la.s3.us-west-1.amazonaws.com/thumbnails';

export const tables = {
    "park_christine_emerson_reed": {
        id: "park_christine_emerson_reed",
        placeId: "ChIJx92--cikwoARw22QhUijGmc",
        name: "Christine Emerson Reed Park",
        photo_thumbnail: `${baseURL}/thumbnail_christine_emerson.jpg`,
        position: { lat: 34.023150, lng: -118.496247 },
        boardType: "table",
        notes: `Cute little park in a quiet part of Santa Monica. In the northern corner near the intersection 
        of California and Lincoln, there are three wooden chess tables for visitors to use. Pieces are not 
        provided, and one table has a bench removed to allow access for wheelchairs. There are large trees 
        nearby, but the shade does not reach the tables.
        `,
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Santa Monica",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/UTZcTF4Qvthgp82b8" },
            { iconType: "meetup", url: "https://www.meetup.com/santa-monicas-christine-emerson-reed-park-chess-meetup/" },
        ],
        images: [
            {
                "id": "IMG_5515",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/park_christine_emerson_reed/IMG_5515.jpg"
            },
            {
                "id": "IMG_5516",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/park_christine_emerson_reed/IMG_5516.jpg"
            },
        ]
    },
    "park_echo_park_lake": {
        id: "park_echo_park_lake",
        placeId: "ChIJVd4G6gTHwoAR9IgRs-bC1pk",
        name: "Echo Park Lake",
        photo_thumbnail: `${baseURL}/thumbnail_echo_park_lake.jpg`,
        position: { lat: 34.070644, lng: -118.260165 },
        boardType: "table",
        notes: `Echo Park Lake was renovated a few years back and has been beautiful ever since. 
        There are paddle boats, food vendors, playgrounds, local wildlife, and lots of nature. 
        Unfortunately, this park does not have any chess tables. However, they do have one table 
        that is dedicated to chess, and for that they receive honorable mention.
        `,
        purchase_required: false,
        last_confirmed_seen: "2022",
        neighborhood: "Echo Park",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/4PGa1snFy1ozKtHcA" },
        ],
        images: [
            {
                "id": "IMG_5646",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/park_echo_park_lake/IMG_5646.jpg"
            },
            {
                "id": "IMG_5645",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/park_echo_park_lake/IMG_5645.jpg"
            },
        ]
    },
    "bar_el_prado": {
        id: "bar_el_prado",
        name: "El Prado",
        photo_thumbnail: `${baseURL}/thumbnail_el_prado.jpg`,
        position: { lat: 34.07788930330397, lng: -118.25951590973354 },
        boardType: "table",
        notes: `El Prado is a bar on Sunset in Echo Park. All of the outdoor tables on the sidewalk are 
        painted to look like chess tables. Ask the bartender for pieces to play a game.
        `,
        purchase_required: true,
        pieces: "Available upon request.",
        last_confirmed_seen: "2022",
        neighborhood: "Echo Park",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/EJDN9rsGG1uyQ44S8" },
            { iconType: "instagram", url: "https://www.instagram.com/elpradobar" },
            { iconType: "twitter", url: "https://twitter.com/elpradobar" },
            { iconType: "facebook", url: "https://www.facebook.com/el-prado-12088118310/" },
        ],
        images: [
            {
                "id": "IMG_5654",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_el_prado/IMG_5654.jpg"
            },
            {
                "id": "IMG_5661",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_el_prado/IMG_5661.jpg"
            },
        ]
    },
    "bar_verdugo": {
        id: "bar_verdugo",
        name: "Verdugo Bar",
        photo_thumbnail: `${baseURL}/thumbnail_verdugo_bar.jpg`,
        position: { lat: 34.11384335377409, lng: -118.23506596571589 },
        boardType: "table",
        notes: "Two tables with boards painted on are situated in the back patio, available to patrons. Pieces are not provided.",
        purchase_required: true,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        neighborhood: "Glassell Park",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://g.page/VerdugoBar?share" },
            { iconType: "website", url: "http://www.verdugobar.com/los-angeles" },
            { iconType: "instagram", url: "https://www.instagram.com/verdugobar/" },
            { iconType: "twitter", url: "https://twitter.com/verdugobar" },
            { iconType: "facebook", url: "https://www.facebook.com/verdugobar" },
        ],
        images: [
            {
                "id": "IMG_5226",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_verdugo/IMG_5226.jpg"
            },
            {
                "id": "IMG_5227",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/bar_verdugo/IMG_5227.jpg"
            },
        ]
    },
    "pershing_square": {
        id: "pershing_square",
        name: "Pershing Square",
        photo_thumbnail: `${baseURL}/thumbnail_pershing_square.jpg`,
        position: { lat: 34.048734819115545,  lng: -118.2531753457086 },
        boardType: "table",
        neighborhood: "Downtown LA",
        day: "Any",
        notes: `Four cement tables with boards painted on. Pieces and chairs available at the 
        Recreation Center (213-847-4970). Limited shade.`,
        purchase_required: false,
        pieces: "Available at the Recreation Office.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/YoGnPVfxjmfGynQCA" },
            { iconType: "website", url: "https://www.laparks.org/pershingsquare/" },
        ],
        images: [
            {
                "id": "IMG_8652",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/pershing_square/IMG_8652.jpg"
            },
            {
                "id": "IMG_8649",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/pershing_square/IMG_8649.jpg"
            },
            {
                "id": "IMG_8650",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/pershing_square/IMG_8650.jpg"
            },
            {
                "id": "IMG_8651",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/pershing_square/IMG_8651.jpg"
            },
            {
                "id": "IMG_8653",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/pershing_square/IMG_8653.jpg"
            },
        ]
    },
    "virginia_avenue_park": {
        id: "virginia_avenue_park",
        name: "Virginia Avenue Park",
        photo_thumbnail: `${baseURL}/thumbnail_virginia_avenue_park.jpg`,
        position: { lat: 34.0213434, lng: -118.4672702 },
        boardType: "table",
        neighborhood: "Santa Monica",
        day: "Any",
        notes: `Great park connected to a school and a public library. Out front, next to the basketball courts, 
        there are three wooden chess tables with boards installed. One table has a bench removed to provide space 
        for a wheelchair. Unfortunately the chess tables don't have many trees nearby and therefor do not have much 
        shade.
        `,
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/xyzbtJrHajmP82wr8" },
            { iconType: "website", url: "https://www.santamonica.gov/places/parks/virginia-avenue-park" },
        ],
        images: [
            {
                "id": "IMG_8488",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virginia_avenue_park/IMG_8488.jpg"
            },
            {
                "id": "IMG_8475",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virginia_avenue_park/IMG_8475.jpg"
            },
            {
                "id": "IMG_8480",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virginia_avenue_park/IMG_8480.jpg"
            },
            {
                "id": "IMG_8483",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virginia_avenue_park/IMG_8483.jpg"
            },
            {
                "id": "IMG_8487",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/virginia_avenue_park/IMG_8487.jpg"
            },
        ]
    },
    "westwood_gardens_park": {
        id: "westwood_gardens_park",
        name: "Westwood Gardens Park",
        photo_thumbnail: `${baseURL}/thumbnail_westwood_gardens_park.jpg`,
        position: { lat: 34.057463, lng: -118.441658 },
        boardType: "table",
        neighborhood: "Westwood",
        day: "Any",
        notes: "Three cement chess tables with tile boards inlaid. All three tables have space for wheelchair access. Partial to full shade available. Pieces not provided.",
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/MMi7EHWtG3jsoyeK6" },
        ],
        images: [
            {
                "id": "IMG_8217",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8217.jpg"
            },
            {
                "id": "IMG_8220",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8220.jpg"
            },
            {
                "id": "IMG_8221",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8221.jpg"
            },
            {
                "id": "IMG_8222",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8222.jpg"
            },
            {
                "id": "IMG_8223",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8223.jpg"
            },
            {
                "id": "IMG_8224",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8224.jpg"
            },
            {
                "id": "IMG_8225",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8225.jpg"
            },
            {
                "id": "IMG_8230",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/westwood_gardens_park/IMG_8230.jpg"
            },
        ]
    },
    "venice_beach_tennis": {
        id: "venice_beach_tennis",
        name: "Venice Beach Tennis Courts",
        photo_thumbnail: `${baseURL}/thumbnail_venice_beach_tennis.jpg`,
        position: { lat: 33.984995, lng: -118.472212 },
        boardType: "table",
        neighborhood: "Venice",
        day: "Any",
        notes: "Single metal table with chess board installed. Black squares have worn away with time, but have been drawn back on with marker. One bench missing to accommodate wheelchairs. Can be found in the walkway between the tennis courts. No shade. Pieces not provided.",
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/FFiSbsVFi8igKjsj8" },
        ],
        images: [
            {
                "id": "IMG_8910",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_tennis/IMG_8910.jpg"
            },
            {
                "id": "IMG_8912",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_tennis/IMG_8912.jpg"
            },
            {
                "id": "IMG_8915",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_tennis/IMG_8915.jpg"
            },
            {
                "id": "IMG_8918",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_tennis/IMG_8918.jpg"
            },
            {
                "id": "IMG_8919",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_tennis/IMG_8919.jpg"
            },

        ]
    },
    "ocean_front_walk": {
        id: "ocean_front_walk",
        name: "Ocean Front Walk",
        photo_thumbnail: `${baseURL}/thumbnail_ocean_front_walk.jpg`,
        position: { lat: 33.989544, lng: -118.476099 },
        boardType: "table",
        neighborhood: "Venice",
        notes: `
        Games of chess can be found along Ocean Front Walk in Venice Beach almost any day of the week. 
        While the <a href='https://www.chess-la.com/chess-site/venice_beach_knights' target='_blank'>Venice 
        Beach Knights</a> are undoubtedly the best place for chess in the area, there are 
        other options available as well. Numerous folks play chess for money along the boardwalk and will challenge 
        passerby to games in exchange for money. Playing these games can be fun, but be careful! These 
        players can be very tricky and may separate you from more of your money than you planned.
        `,
        purchase_required: true,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/CP4LmHKLLA7P2er96" },
        ],
        images: [
            {
                "id": "IMG_8981",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ocean_front_walk/IMG_8981.jpg"
            },
            {
                "id": "IMG_8971",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ocean_front_walk/IMG_8971.jpg"
            },
            {
                "id": "IMG_8972",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ocean_front_walk/IMG_8972.jpg"
            },
            {
                "id": "IMG_8973",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ocean_front_walk/IMG_8973.jpg"
            },
            {
                "id": "IMG_8978",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ocean_front_walk/IMG_8978.jpg"
            },
        ]
    },
    "hollywood_selma_park": {
        id: "hollywood_selma_park",
        name: "Selma Park",
        photo_thumbnail: `${baseURL}/thumbnail_hollywood_selma_park.jpg`,
        position: { lat: 34.099914, lng: -118.333037 },
        boardType: "table",
        notes: `Unfortunately, this area is moderately unsafe. The city built a very tall wrought 
        iron fence around the park, which means there is only one entrance/exit to the area. However, the park itself is very shady
        and beautiful. There is a smaller area in the back with a children's playground and three round 
        cement chess tables. This park is also right behind the touristy area of Hollywood, and is easily 
        walkable from the Hollywood/Highland stop on the Red Line train.`,
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        url: "https://goo.gl/maps/VL6rooumZmBJzUdYA",
        neighborhood: "Hollywood",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/VL6rooumZmBJzUdYA" },
            { iconType: "website", url: "https://www.laparks.org/park/selma" },    
        ],
        images: [
            {
                "id": "IMG_0042",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/hollywood_selma_park/IMG_0042.jpg"
            },
            {
                "id": "IMG_0050",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/hollywood_selma_park/IMG_0050.jpg"
            },
            {
                "id": "IMG_0045",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/hollywood_selma_park/IMG_0045.jpg"
            },
            {
                "id": "IMG_0043",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/hollywood_selma_park/IMG_0043.jpg"
            },
            {
                "id": "IMG_0040",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/hollywood_selma_park/IMG_0040.jpg"
            },
            
        ]
    },
    "clutters_park": {
        id: "clutters_park",
        name: "Clutter's Park",
        photo_thumbnail: `${baseURL}/thumbnail_clutters_park.jpg`,
        position: { lat: 33.9309104, lng: -118.4116516 },
        boardType: "table",
        neighborhood: "El Segundo",
        day: "Always",
        notes: `Four cement and metal tables with plastic black and red embedded chessboards situated along 
        the sidewalk. Three tables have partial shade from nearby trees and one is in full sun. Pieces are not provided. 
        Numerous photographers
        in the area taking advantage of the views of airplanes coming and going from LAX.`,
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/ETtPK6x8tPwrTgkM8" },
            { iconType: "website", url: "https://losangelesexplorersguild.com/2022/02/07/planespotting-in-clutters-park/" },
        ],
        images: [
            {
                "id": "IMG_6442",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/clutters_park/IMG_6442.jpg"
            },
            {
                "id": "IMG_6447",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/clutters_park/IMG_6447.jpg"
            },
            {
                "id": "IMG_6452",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/clutters_park/IMG_6452.jpg"
            },
            {
                "id": "IMG_6449",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/clutters_park/IMG_6449.jpg"
            },
        ]
    },
    "kenneth_hahn_park": {
        id: "kenneth_hahn_park",
        name: "Kenneth Hahn Park",
        photo_thumbnail: `${baseURL}/thumbnail_kenneth_hahn_park.jpg`,
        position: { lat: 34.0061805, lng: -118.3646324 },
        boardType: "table",
        neighborhood: "Baldwin Hills",
        day: "",
        notes: `Several picnic tables scattered throughout the park with stone chessboards inlaid on 
        a single side. One is near the large lake by the parking lot. A few more are in front of the
        visitor's center. Two more are along the left side of the road heading uphill towards the
        playground at the very top of the park.`,
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/ktozEnzSawfFGMtGA" },
            { iconType: "website", url: "https://www.parks.ca.gov/?page_id=612" },
        ],
        images: [
            {
                "id": "KennethHahnPark1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/kenneth_hahn_park/IMG_6506.jpg"
            },
            {
                "id": "KennethHahnPark2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/kenneth_hahn_park/IMG_6507.jpg"
            },
            {
                "id": "KennethHahnPark3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/kenneth_hahn_park/IMG_6509.jpg"
            },
            {
                "id": "KennethHahnPark4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/kenneth_hahn_park/IMG_6505.jpg"
            },
            {
                "id": "KennethHahnPark5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/kenneth_hahn_park/IMG_6519.jpg"
            },
            {
                "id": "KennethHahnPark6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/kenneth_hahn_park/IMG_6521.jpg"
            },
        ]
    },
    "homebound_brew_haus": {
        id: "homebound_brew_haus",
        name: "Homebound Brew Haus",
        photo_thumbnail: `${baseURL}/thumbnail_homebound_brew_haus.jpg`,
        position: { lat: 34.05567685371545, lng: -118.23657655498579 },
        boardType: "table",
        notes: `A large, spacious brewery with high ceilings connected to Union Station. The 
        architecture of the building is the same blend of Spanish Colonial and Art Deco as Union Station 
        itself. There are multiple games available for patrons and commuters, including three beautiful 
        Art Deco-style tables with two chess boards inlaid in each. Wooden pieces are available by request 
        from the bartender. Pieces are 
        stored in beer glasses and are not sorted. Ask for a few cups to guarantee that you get all of the 
        pieces needed for a game. As bonus, because this brewery is connection to Union Station, it can be 
        easily reached by almost any train in Los Angeles.
        `,
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/o1t6gdqxEZvtUG2A8" },
            { iconType: "website", url: "https://www.homeboundbrewhaus.com/" },
            { iconType: "instagram", url: "https://www.instagram.com/homeboundbrew/" },
            { iconType: "facebook", url: "https://www.facebook.com/homeboundbrew" },
        ],
        images: [
            {
                "id": "HomeboundBrewHaus5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1978.jpg"
            },
            {
                "id": "HomeboundBrewHaus11",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_2002.jpg"
            },
            {
                "id": "HomeboundBrewHaus1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1953.jpg"
            },
            {
                "id": "HomeboundBrewHaus2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1954.jpg"
            },
            {
                "id": "HomeboundBrewHaus3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1958.jpg"
            },
            {
                "id": "HomeboundBrewHaus4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1962.jpg"
            },
            {
                "id": "HomeboundBrewHaus7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1986.jpg"
            },
            {
                "id": "HomeboundBrewHaus8",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1992.jpg"
            },
            {
                "id": "HomeboundBrewHaus9",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1995.jpg"
            },
            {
                "id": "HomeboundBrewHaus10",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/homebound_brew_haus/IMG_1997.jpg"
            },
            
        ]
    },
    "rhythm_room_la": {
        id: "rhythm_room_la",
        name: "Rhythm Room LA",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.0460409, lng: -118.2556112 },
        boardType: "table",
        neighborhood: "Downtown LA",
        day: "",
        notes: `Chess tables free for patrons to use.`,
        purchase_required: true,
        pieces: "Not provided.",
        last_confirmed_seen: "Unverified",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://g.page/rhythmroomla?share" },
            { iconType: "website", url: "https://www.rhythmroomla.com/" },
            { iconType: "twitter", url: "https://twitter.com/RhythmRoomLA" },
            { iconType: "facebook", url: "https://www.facebook.com/rhythmroomla/" },
        ],
    },
    "ord_and_yale_street_park": {
        id: "ord_and_yale_street_park",
        name: "Ord and Yale Street Park",
        photo_thumbnail: `${baseURL}/thumbnail_ord_and_yale_street_park.jpg`,
        position: { lat: 34.06100909206659, lng: -118.24142435854615 },
        boardType: "table",
        neighborhood: "Chinatown",
        notes: `
        Ord & Yale Street Park is next door to the Chinatown branch of the 
        <a href='https://www.lapl.org/' target='_blank'>LAPL</a>; a two-minute walk 
        from the 81/94 bus lines, and about a mile walk from Union Station.
        <br />
        <br />
        The park is gated with digital keyless locks. The gates are closed but unlocked during 
        open hours, which can make it appear as if the park is private and requires a code to 
        enter. However, this is not true. The park is public and the side gates along the staircase 
        can be opened without a code during daylight hours.
        <br />
        <br />
        To find the chess tables, follow the Dragon Road to the "Picnic Area" next to the Bamboo Garden Room. 
        Behind the children's kompan area, there are four picnic tables. Two picnic tables have metal boards 
        installed for both chess and Xiangqi 象棋 available for play. Pieces are not provided.
        `,
        purchase_required: false,
        pieces: "Not provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/itmhRcgGWBdoecU18" },
            { iconType: "website", url: "https://www.laparks.org/park/ord-and-yale-street-park" },
        ],
        images: [
            {
                "id": "OrdPark5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2744.jpg"
            },
            {
                "id": "OrdPark10",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2755.jpg"
            },
            {
                "id": "OrdPark1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2724.jpg"
            },
            {
                "id": "OrdPark2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2725.jpg"
            },
            {
                "id": "OrdPark3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2731.jpg"
            },
            {
                "id": "OrdPark4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2734.jpg"
            },
            
            {
                "id": "OrdPark6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2746.jpg"
            },
            {
                "id": "OrdPark7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2747.jpg"
            },
            {
                "id": "OrdPark8",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2750.jpg"
            },
            {
                "id": "OrdPark9",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2751.jpg"
            },
            {
                "id": "OrdPark11",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ord_and_yale_street_park/IMG_2759.jpg"
            },
        ]
    },
    "rancho_simi_community_park": {
        id: "rancho_simi_community_park",
        name: "Rancho Simi Community Park",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.26480465607456, lng: -118.76348420808557 },
        boardType: "table",
        notes: "Chess tables throughout the bar area.",
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/PuWmHR91UQL1puBEA" },
        ],
    },
    "glendale_chess_park": {
        id: "glendale_chess_park",
        name: "Rancho Simi Community Park",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.26480465607456, lng: -118.76348420808557 },
        boardType: "historic",
        notes: `From 2004-2018, Glendale had its own chess park, modeled after the famous park in Santa Monica. 
        It included chess themed light towers, 16 pre-cast chess tables, and chess piece structures. The chess
        pieces demarcated the entry points to the park and differentiated the activity zones within the park. 
        <br />
        In 2018, the city chose to demolish the park as a part of a project to revitalize the area. 
        `,
        // TODO https://twitter.com/chesspark_2017/status/1053018407149293568

        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/vS2JY6KeiAQr9R1Y7" },
            { iconType: "website", url: "http://www.seecalifornia.com/parks/glendale-chess-park.html" },
        ],
    },
};
