import React from "react";
import MapView from "./views/MapView";
import Menu from "./components/menu/Menu";

export default function App() {

  return (
    <>
      <Menu/>
      <MapView />
    </>
  );
}
