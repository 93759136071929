let baseURL = 'https://chess-la.s3.us-west-1.amazonaws.com/thumbnails';

export const clubs = {
    "la_chess_social": {
        id: "la_chess_social",
        name: "Los Angeles Chess Social",
        photo_thumbnail: `${baseURL}/thumbnail_social.jpg`,
        position: { lat: 34.1469601, lng: -118.1523767 },
        boardType: "club",
        neighborhood: "Pasadena",
        day: "Monthly",
        notes: `The Los Angeles Chess Social is the largest chess social on the West Coast. Events take place at  
        rotating venues across Pasadena and generally last from 8:00pm to 1:00am . As of 
        this writing, the Los Angeles Chess Social has met at Der Wolf, Der Rathskeller, Choza 96, The Eden Garden, 
        The Vertical Wine Bistro, and the Old Towne Pub. The event is accompanied by live music. Boards and pieces are provided.`,
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "website", url: "http://www.chesssocial.org/" },
            { iconType: "instagram", url: "https://www.instagram.com/chess_social/" },
        ],
        images: [
            {
                "id": "LAChessSocial1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_social/IMG_8732.jpg"
            },
            {
                "id": "LAChessSocial2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_social/IMG_8725.jpg"
            },
            {
                "id": "LAChessSocial3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_social/IMG_8731.jpg"
            },
            {
                "id": "LAChessSocial4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_social/IMG_8739.jpg"
            },
            {
                "id": "LAChessSocial5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_social/IMG_8744.jpg"
            },
            {
                "id": "LAChessSocial6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_social/social.jpg"
            },
        ]
    },
    "tanners_culver_city": {
        id: "tanners_culver_city",
        name: "Chess Club of Los Angeles @ Tanner's Coffee Co.",
        photo_thumbnail: `${baseURL}/thumbnail_tanners_culver_city.jpg`,
        position: { lat: 34.003953350081645, lng: -118.40839194642454 },
        boardType: "tournament",
        notes: `Tanners is the definition of the neighborhood coffee shop. Chess games are frequent 
        here and can often get quite competitive. The Chess Club of Los Angeles meets here to run official 
        USCF rated tournaments for all ages. Come by on a weekend between 12p-5p for unrated chess games, 
        or after 5pm for USCF rated tournaments. If you want to play chess but don't feel ready for the 
        tournaments, come by any other time and play one of the boards available inside the shop for 
        patrons to play casually.
        `,
        neighborhood: "Culver City",
        purchase_required: true,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/AUqvixmpwT7gM5RH9" },
            { iconType: "meetup", url: "https://www.meetup.com/chess-club-of-los-angeles/" },
            { iconType: "instagram", url: "https://www.instagram.com/tanners_coffee_co/?hl=en" },
            { iconType: "facebook", url: "https://www.facebook.com/tannerscoffee/" },
        ],
        images: [
            {
                "id": "Tanners1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/tanners_culver_city/IMG_0370.jpg"
            },
            {
                "id": "Tanners2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/tanners_culver_city/IMG_0376.jpg"
            },
        ]

    },
    "larchmont_le_pain": {
        id: "larchmont_le_pain",
        name: "Le Pain Quotidien on Larchmont",
        photo_thumbnail: `${baseURL}/thumbnail_larchmont_le_pain.jpg`,
        position: { lat: 34.0747774, lng: -118.3258774 },
        boardType: "group",
        neighborhood: "Larchmont",
        day: "Sundays @ 1:30pm?",
        notes: `Every Sunday at 1:30pm, the aptly named <i>Chess at Le Pain Quotidien</i> meetup group 
        meets at 
        the <a href="https://larchmontla.com/le-pain-quotidien/" target="_blank">LPQ in Larchmont Village</a> for 
        pickup chess games. 
        Registration via <a href="https://meetu.ps/c/3xZgK/WPJfR/a" target="_blank">meetup.com</a> is encouraged, but 
        is not required. Players bring their own sets and meet at the long central table in the front of the cafe. If
        you don't have your own set to bring, come anyway and wait for a board to free up.`,
        purchase_required: true,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/AUqvixmpwT7gM5RH9" },
            { iconType: "meetup", url: "https://meetu.ps/c/3xZgK/WPJfR/a" },
        ],
        images: [
            {
                "id": "LePain5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/larchmont_le_pain/IMG_2464.jpg"
            },
            {
                "id": "LePain2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/larchmont_le_pain/IMG_2439.jpg"
            },
            {
                "id": "LePain3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/larchmont_le_pain/IMG_2450.jpg"
            },
            {
                "id": "LePain4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/larchmont_le_pain/IMG_2451.jpg"
            },
            {
                "id": "LePain1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/larchmont_le_pain/IMG_2435.jpg"
            },
        ]
    },
    "ilcaffe_dtla": {
        id: "ilcaffe_dtla",
        name: "Ilcaffé",
        photo_thumbnail: `${baseURL}/thumbnail_ilcaffe_dtla.jpg`,
        position: { lat: 34.042598310181496, lng: -118.25604582959322 },
        boardType: "group",
        neighborhood: "Downtown LA",
        day: "",
        notes: `Ilcaffé is a Swedish cafe at the base of the historic Eastern building on the corner of 
        Broadway and 9th in DTLA. The location is gorgeous, with the Eastern building in the background,
        and rows of old trees providing shade for the outdoor sidewalk seating. Pickup games of chess are
        common here. According to word of mouth, any evening Thursday - Sunday could have games going, but
        Sunday is the most popular day. The cafe does not provide boards or pieces, so bring your own supplies
        if you want to come and play chess.`,
        purchase_required: true,
        pieces: "Not provided.",
        verified: true,
        last_confirmed_seen: "2022",
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/yxafkyDEujxs13Ds6" },
            { iconType: "instagram", url: "https://www.instagram.com/ilcaffe.la" },
            { iconType: "facebook", url: "https://www.facebook.com/ilcaffedtla/" },
        ],
        images: [
            {
                "id": "Ilcaffe1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ilcaffe_dtla/IMG_0211.jpg"
            },
            {
                "id": "Ilcaffe2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ilcaffe_dtla/IMG_0210.jpg"
            },
            {
                "id": "Ilcaffe3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ilcaffe_dtla/IMG_0203.jpg"
            },
            {
                "id": "Ilcaffe4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ilcaffe_dtla/IMG_0227.jpg"
            },
            {
                "id": "Ilcaffe5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ilcaffe_dtla/IMG_0211.jpg"
            },
            {
                "id": "Ilcaffe6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ilcaffe_dtla/IMG_0224.jpg"
            },
        ]
    },
    "make_a_move_la": {
        id: "make_a_move_la",
        name: "Make A Move LA",
        photo_thumbnail: `${baseURL}/thumbnail_make_a_move_la.jpg`,
        position: { lat: 34.039574854642616, lng: -118.34141298804356 },
        boardType: "tournament",
        day: "Unknown",
        notes: `Make A Move LA is a non-profit organization dedicated to community development through 
        public chess events. Chess tournaments hosted by Make A Move LA are fun, social community events 
        with music, spoken word, and live performances happening in between games. Events are free for spectators 
        and cost a small fee for tournament participants. Donations are welcome to support both Make A Move LA and
        its subsidiary, the Youth See More Foundation. More about Make A Move LA can be found on their 
        instagram 
        <a href="https://www.instagram.com/make.a.move.la" target="_blank" rel="noopener noreferrer">@make.a.move.la</a></i>, 
        or in 
        <a href="https://lasentinel.net/make-a-move-la-hosts-entertainment-driven-chess-tournament.html" target="_blank" rel="noopener noreferrer">the LA Sentinel</a></i>. 
        `,
        neighborhood: "Mid-City",
        purchase_required: true,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        url: "",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/yxafkyDEujxs13Ds6" },
            { iconType: "instagram", url: "https://www.instagram.com/make.a.move.la" },
            { iconType: "website", url: "https://www.eventbrite.com/o/make-a-move-la-x-palm-grove-social-49481864293" },
            { iconType: "youtube", url: "https://www.youtube.com/channel/UCaBrfSTaKZ4izRUdb6Mc5_w" },
        ],
        images: [
            {
                "id": "MakeAMoveLA1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0422.jpg"
            },
            {
                "id": "MakeAMoveLA2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0441.jpg"
            },
            {
                "id": "MakeAMoveLA3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0386.jpg"
            },
            {
                "id": "MakeAMoveLA4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0394.jpg"
            },
            {
                "id": "MakeAMoveLA5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0399.jpg"
            },
            {
                "id": "MakeAMoveLA6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0401.jpg"
            },
            {
                "id": "MakeAMoveLA7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0419.jpg"
            },
            {
                "id": "MakeAMoveLA8",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0431.jpg"
            },
            {
                "id": "MakeAMoveLA9",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/make_a_move_la/IMG_0438.jpg"
            },
        ]
    },
    "venice_beach_knights": {
        id: "venice_beach_knights",
        name: "Venice Beach Knights",
        photo_thumbnail: `${baseURL}/thumbnail_venice_beach_knights.jpg`,
        position: { lat: 33.989213320787506, lng: -118.47580539745313 },
        boardType: "club",
        neighborhood: "Venice",
        notes: `
        The Venice Beach Knights is "a chess club with no walls" run by Tim Caldwell. Tim runs a fun and respectful club, which 
        emphasizes good sportsmanship and friendly behavior. 
        <br />
        <br />
        This chess club has four main rules: Be polite, always return the pieces to their correct position, 
        shake hands and say thank you after each game, and no discussing religion, politics, or other heated topics over the chess 
        board. He let me know that if anyone tries to discuss something unwelcome with me, that I should simply reply with "What does 
        that have to do with chess?" Tim Caldwell is LA's kind and supportive chess uncle that we never knew we needed.
        <br />
        <br />
        If you'd like to visible the VB knights, you can usually find their blue tent along the Venice Beach boardwalk at the intersection 
        of Ocean Front Walk and Westminster Ave. However, the exact location 
        may change slightly from day to day. Occasionally Tim will post where they are for the day  
        on his <a href="https://www.youtube.com/channel/UCMlQ3CBxPtZEnA8wdhMZyvg" target="_blank">YouTube channel</a>, but this 
        cannot be guaranteed. 
        <br />
        <br />
        According to their website, the club is open from Friday - Monday for most of the day. 
        In my experience, the best time to go to find other players at the club is the weekend. This is particularly true in the summer 
        time when the tourists are out in droves. 
        <br />
        <br />
        Watch out, though: There are other chess players along the boardwalk as well. These other folks are 
        usually just playing games for money, and are unaffiliated with the Venice Beach Knights club. 
        While the VB Knights do charge a membership fee (as do many 
        chess clubs), they are notably <i>not</i> trying to scam or hustle players. Tim Caldwell told <a href="https://www.laweekly.com/how-to-become-a-member-of-venice-beachs-chess-club-without-walls/" target="_blank">LA Weekly</a> specifically 
        that he was not interested in chess hustling. He instead wanted "to start a chess club to promote 
        and teach chess... and to build a chess community." As he put it: "It might be less money, but it's more fulfillment."
        `,
        purchase_required: true,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://g.page/venicebeachknights?share" },
            { iconType: "website", url: "https://venice-beach-knights-chess-club.business.site/" },
            { iconType: "website", url: "https://venicebeachknightsstore.company.site/" },
            { iconType: "youtube", url: "https://www.youtube.com/channel/UCMlQ3CBxPtZEnA8wdhMZyvg" },
            { iconType: "facebook", url: "https://www.facebook.com/pages/category/Community/Venice-beach-knights-111466812870920/" },
        ],
        images: [
            {
                "id": "VBK10",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2993.jpg"
            },
            {
                "id": "VBK6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2988.jpg"
            },
            {
                "id": "VBK8",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2991.jpg"
            },
            {
                "id": "VBK7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2989.jpg"
            },
            {
                "id": "VBK9",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2992.jpg"
            },
            {
                "id": "VBK4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2986.jpg"
            },
            {
                "id": "VBK1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2973.jpg"
            },
            {
                "id": "VBK2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2976.jpg"
            },
            {
                "id": "VBK3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/venice_beach_knights/IMG_2980.jpg"
            }, 
        ]
    },
    "la_central_library_club": {
        id: "la_central_library_club",
        name: "Los Angeles Central Library Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_la_central_library_club.jpg`,
        position: { lat: 34.05032940534399, lng: -118.25502152104967 },
        boardType: "club",
        neighborhood: "Downtown",
        day: "Weekly, Mondays 5:30pm - 7:00pm",
        notes: `The Los Angeles Central Library is a beautiful Art Deco building from the 1920s. 
        On the second floor, there is a chess club that meets in the Art Department on Mondays 
        from 5:30 - 7:00pm. Boards and pieces are provided, no registration needed.
        Contact art@lapl.org / (213) 228-7225 for more information.`,
        purchase_required: false,
        pieces: "Provided.",
        last_confirmed_seen: "2022",
        url: "",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/6YeuvTh1ei1KJdJh6" },
            { iconType: "website", url: "https://www.lapl.org/branches/central-library/departments/art-music-recreation" },
            { iconType: "instagram", url: "https://www.instagram.com/centralart/" },
            { iconType: "facebook", url: "https://www.facebook.com/people/Art-Music-Recreation-Department-Los-Angeles-Public-Library/100064745704641/" },
        ],
        images: [
            {
                "id": "LALibrary0",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_2052.jpg"
            },
            {
                "id": "LALibrary1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6812.jpg"
            },
            {
                "id": "LALibrary2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6782.jpg"
            },
            {
                "id": "LALibrary3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6775.jpg"
            },
            {
                "id": "LALibrary4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6777.jpg"
            },
            {
                "id": "LALibrary5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6813.jpg"
            },
            {
                "id": "LALibrary6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6815.jpg"
            },
            {
                "id": "LALibrary7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6816.jpg"
            },
            {
                "id": "LALibrary8",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_central_library_club/IMG_6817.jpg"
            },
        ]
    },
    "ucla_chess_club": {
        id: "ucla_chess_club",
        name: "UCLA Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_ucla_chess_club.jpg`,
        position: { lat: 34.0712266, lng: -118.4499523 },
        boardType: "club",
        neighborhood: "Westwood",
        day: "Thursdays 3:30pm-5:30pm and Fridays 4:00pm-6:00pm",
        notes: `The UCLA Chess Club provides a casual and competitive forum for UCLA 
        students of all experience levels. Meetings take place at the Wooden
        Center Games Lounge during the academic year. Free lessons are provided for 
        students who wants to learn how to play chess. They also regularly attend and
        host both intercollegiate and USCF tournaments throughout the year.`,
        purchase_required: false,
        pieces: "Provided",
        last_confirmed_seen: "2022",
        verified: true,
        url: "",
        website: "",
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/e3Pz4X8x1FKwP8c28" },
            { iconType: "website", url: "https://community.ucla.edu/studentorg/2449" },
            { iconType: "tiktok", url: "https://www.tiktok.com/discover/ucla-chess-club?lang=en" },
            { iconType: "instagram", url: "https://www.instagram.com/uclachessclub/?hl=en" },
            { iconType: "twitter", url: "https://twitter.com/uclachessclub?lang=en" },
            { iconType: "facebook", url: "https://www.facebook.com/groups/uclachessclub/" },
            { iconType: "chesscom", url: "https://www.chess.com/club/ucla-bruins-chess-club" },
        ],
        images: [
            {
                "id": "IMG_6220",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ucla_chess_club/IMG_6220.jpg"
            },
            {
                "id": "IMG_6222",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ucla_chess_club/IMG_6222.jpg"
            },
            {
                "id": "IMG_6223",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/ucla_chess_club/IMG_6223.jpg"
            },
        ]
    },
    "la_chess_ladder": {
        id: "la_chess_ladder",
        name: "LA Chess Ladder",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.40360902740528, lng: -118.52896655296271 },
        boardType: "tournament",
        day: "Unknown",
        notes: `The LA Chess Ladder has no set location and can happen wherever players would like to play. 
        Consult the LA Chess Ladder website for rules on how to challenge other members to a game, as well as 
        for dates and times of upcoming tournaments.
        `,
        neighborhood: "Los Angeles",
        purchase_required: false,
        pieces: "Not Provided.",
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "website", url: "http://lachessladder.com/" },
            { iconType: "facebook", url: "https://www.facebook.com/LaChessLadder/" },
            { iconType: "youtube", url: "https://www.youtube.com/channel/UCNI7Jxn3zfCnoH1Nyn3ax7Q" },
        ],
        
    },
    "la_chess_boxing": {
        id: "la_chess_boxing",
        name: "LA Chess Boxing",
        photo_thumbnail: `${baseURL}/thumbnail_la_chess_boxing.jpg`,
        position: { lat: 34.08395772146233, lng: -118.3596499030479 },
        boardType: "club",
        notes: `In chessboxing, players engage in eleven alternating three-minute rounds of--you 
        guessed it--chess and boxing. In order to win, players must either "Take the king or win in the ring." 
        <br />
        <br />
        The sport was <a href='https://www.uscannenbergmedia.com/2021/10/10/the-sports-search-chess-boxing/' target='_blank'>invented 
        in Berlin in 2003</a>, but it wasn't until 2009 that it arrived in the United States. LA was the first US city 
        to have an official chessboxing club, thanks to <a href='https://lachessboxing.com/' target='_blank'>Los Angeles Chessboxing Club</a>, founded 
        by <a href='https://www.mightykingdrew.com/projects/la-chessboxing/' target='_blank'>Andrew &#8220;The 
        Fightin Philanthropist&#8221; McGregor</a>. 
        <br />
        <br />
        Alongside bringing the sport to the US, McGregor also brought a new 
        rule: requiring fighters to wear headgear and therefor 
        <a href='http://www.uschess.org/index.php/March-/Chessboxing-Hits-Los-Angeles.html' target='_blank'>reducing 
        the chance of injury and allowing less experienced boxers to fight</a>. 
        <a href='https://jennifershahade.com/' target='_blank'>Jennifer Shahade</a>--two-time 
        US Women's Chess Champion, author, and advocate for woman in chess--notes that this is intended also to make the 
        sport more welcoming to women, who are drastically underrepresented in chess.
        <br />
        <br />
        According to <a href='https://www.laweekly.com/andrew-mcgregor-from-journalism-to-chessboxing/' target='_blank'>LA Weekly</a>, 
        the founding of the LA Chessboxing Club was "magical." In their article, they discuss how chessboxing helped McGregor to cope after 
        he returned from the Congo for his work with 
        the <a href='http://tizianoproject.org/' target='_blank'>Tiziano Project</a>, where he taught journalism to refugees. 
        &#8220;Through the course of boxing, I was able to feel again. It's a bit extreme, but I remember this guy hit me in the ribs and I felt pain again.&#8221;
       `,
        neighborhood: "Hollywood",
        purchase_required: false,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/zSvZAaoDbdjBvEHT9" },
            { iconType: "website", url: "https://lachessboxing.com/" },
            { iconType: "facebook", url: "https://www.facebook.com/LAChessboxing/" },
        ],
        images: [
            {
                "id": "LACB1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_boxing/chessbox1.jpg"
            },
            {
                "id": "LACB2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_boxing/chessbox2.jpg"
            },
            {
                "id": "LACB3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_boxing/chessbox3.jpg"
            },
            {
                "id": "LACB4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_boxing/chessbox4.jpg"
            },
            {
                "id": "LACB5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/la_chess_boxing/chessbox5.jpg"
            },
        ]
    },
    "whittier_chess_club": {
        id: "whittier_chess_club",
        name: "Whittier Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_whittier_chess_club.jpg`,
        position: { lat: 33.9806399, lng: -118.0443357 },
        boardType: "club",
        neighborhood: "Whittier",
        notes: `Free rated tournaments for all US Chess members.
        `,
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/yK2122aWUELNdbeA8/" },
            { iconType: "website", url: "https://sites.google.com/view/whittier-chess-club/home/" },
        ],
        images: [
            {
                "id": "WhittierChessClub",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/whittier_chess_club/IMG_2887.jpg"
            },
        ]
    },
    "usc_trojan_chess_club": {
        id: "usc_trojan_chess_club",
        name: "USC Trojan Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_usc_trojan_chess_club.png`,
        position: { lat: 34.0243846, lng: -118.2905708 },
        boardType: "club",
        neighborhood: "University Park",
        day: "Tuesdays and Fridays at 7:00 p.m",
        notes: `
        USC chess club meets on Tuesdays and Fridays at 7:00pm at the Lyon Center in the conference room. 
        USC also has a 
        <a href="https://dornsife.usc.edu/news/stories/3277/chess-class-teaches-students-how-to-master-the-mind/" target="_blank">chess class</a> 
        which is taught by IM Jack Peters.
        `,
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/X7XCrhTeaypB9vFX8" },
            { iconType: "website", url: "https://sites.google.com/usc.edu/trojan-chess-club/home" },
            { iconType: "instagram", url: "https://www.instagram.com/trojanchessclub/" },
            { iconType: "facebook", url: "https://www.facebook.com/groups/161806550627269/" },
            { iconType: "lichess", url: "https://lichess.org/team/trojan-chess-club" },
            { iconType: "chesscom", url: "https://www.chess.com/club/usc-trojans-chess-club" },
        ],
        images: [
            {
                "id": "USCChessClub",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/usc_trojan_chess_club/usclogo.png"
            },
        ]
    },
    "los_angeles_chess_club": {
        id: "los_angeles_chess_club",
        name: "Los Angeles Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.04523996133171, lng: -118.45208464149916 },
        boardType: "historic",
        neighborhood: "Santa Monica",
        day: "Unknown",
        notes: "Unverified",
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/4Te16E45is1Wb8EV7" },
            { iconType: "instagram", url: "https://www.instagram.com/lachessclub/?hl=en" },
            { iconType: "chesscom", url: "https://www.chess.com/club/los-angeles-chess-club-lacc" },
        ],
    },
    "metro_chess_club": {
        id: "metro_chess_club",
        name: "Metropolitan Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.040450846929566, lng: -118.25532902083658 },
        boardType: "club",
        neighborhood: "Downtown LA",
        day: "Unknown",
        notes: `It is the goal of Metropolitan Chess, Inc to promote and support the game 
        of chess through community outreach and local and national partnerships. They also offer
        private and group tutoring (including online classes during COVID) for players from
        beginner up to tournament level. (Not to be confused with the similarly named London Metropolitan
        Chess Club.)`,
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/bZChD8gHf8sfurqY7" },
            { iconType: "website", url: "https://www.metrochessla.com" },
            { iconType: "facebook", url: "https://www.facebook.com/metrochess/" },
            { iconType: "chesscom", url: "https://www.chess.com/club/metropolitan-chess" },            
        ],
    },
    "los_feliz_chess_club": {
        id: "los_feliz_chess_club",
        name: "Los Feliz Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_los_feliz_chess_club.jpg`,
        position: { lat: 34.09206331419762, lng: -118.27974472313653 },
        boardType: "club",
        neighborhood: "Los Feliz",
        day: "Unknown",
        notes: `Free pickup games every Sunday 2:00pm - 5:00pm @ The Black Cat.`,
        purchase_required: true,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/uWB5wkq37ReMMHDi8" },
            { iconType: "website", url: "https://www.folosophy.com/lfcc" },
            { iconType: "instagram", url: "https://www.instagram.com/losfelizchessclub/?hl=en" },
            { iconType: "chesscom", url: "https://www.chess.com/club/los-feliz-chess-club" },            
        ],
        images: [
            {
                "id": "LFChessClub",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/los_feliz_chess_club/los_feliz_chess_club.jpg"
            },
        ]
    },
    "ventura_county_chess_cub": {
        id: "ventura_county_chess_cub",
        name: "Ventura County Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_ventura_chess_club.jpg`,
        position: { lat: 34.2655528, lng: -119.2105624 },
        boardType: "club",
        neighborhood: "Ventura",
        day: "Mondays @ 6:45pm",
        notes: `The Ventura County Chess Club has been providing chess services continuously 
        to the public for more than 60 years. They meet Monday nights at 6:45pm at Grace Lutheran 
        Church across from the Ventura County Government Center. Players of all skill levels are 
        welcome. Chess playing supplies provided.`,
        purchase_required: false,
        pieces: "Unknown.",
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/aPbWHVHa133Bpj7S6" },
            { iconType: "website", url: "https://venturachess.org/" },
            { iconType: "chesscom", url: "https://www.chess.com/club/ventura-county-chess-club" },            
        ],
    },
    "icea_kids_chess_cub": {
        id: "icea_kids_chess_cub",
        name: "ICEA Kids Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.01289862109032, lng: -118.40260200460294 },
        boardType: "club",
        neighborhood: "Culver City",
        notes: `ICEA Chess Club was founded for kids by kids who love and have passions on 
        chess and later extended for all friends and families who enjoy the chess. They help
        kids grow and play competitive chess.`,
        purchase_required: false,
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/eUKGkch54Rqpmjbs5" },
            { iconType: "website", url: "https://intlcea.org/kids-chess-club" },
            { iconType: "lichess", url: "https://lichess.org/team/icea-scholastic-chess-club" },            
        ],
    },
    "alondra_park_chess_club": {
        id: "alondra_park_chess_club",
        name: "Alondra Park Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 33.88619145464422, lng: -118.34102531854151 },
        boardType: "club",
        neighborhood: "Lawndale",
        notes: `The Alondra Park Chess Club meets every Wednesday from 6:30pm - 9:00pm. 
        They hold USCF-rated Round Robin tournaments with a time control of G55/d5. Typically
        30 - 40 players participate in the tournament and ratings range from  ~1000 - 2000.
        There is a $5 entry fee for a tournament and a $5 annual club membership.`,
        purchase_required: false,
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/PkNe6txKssndgHmK6" },
            { iconType: "website", url: "http://www.alondraparkchessclub.com/" },
            { iconType: "chesscom", url: "https://www.chess.com/club/alondra-park-chess-club" },
        ],
    },
    "sm_bay_chess_club": {
        id: "sm_bay_chess_club",
        name: "Santa Monica Bay Chess Club",
        photo_thumbnail: `${baseURL}/thumbnail_sm_bay_chess_club.jpg`,
        position: { lat: 34.0240984095586, lng: -118.43658148921811 },
        boardType: "club",
        neighborhood: "Santa Monica",
        day: "Tuesdays 7:00pm - 11:00pm",
        notes: `Santa Monica Bay Chess Club might be the oldest chess club in Los Angeles, with history dating 
        back to at least the 1940s. SMBCC meets in a multipurpose room at St. Andrew's Lutheran Church and runs continuous 
        UCSF-rated tournaments with G/1:55 (d5) time control. Sets are furnished for tournament players. Spectators 
        are welcome to come and watch the games. Casual players are welcome as well, although space is limited. If you 
        wish to play, please reach out to the club runners beforehand so they can plan for your arrival.
        Club membership is $75 per year, and tournament entry fees are $10 ($25 to non-members).
        `,
        purchase_required: false,
        last_confirmed_seen: "2022",
        verified: true,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/1x27x1yMD7imEEM37" },
            { iconType: "website", url: "https://sites.google.com/site/santamonicabaychessclub/" },
            { iconType: "facebook", url: "https://www.facebook.com/smbaychessclub/" },
            { iconType: "chesscom", url: "https://www.chess.com/club/santa-monica-bay-chess-club" },
        ],
        images: [
            {
                "id": "SMBCC2",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2542.jpg"
            },
            {
                "id": "SMBCC1",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2534.jpg"
            },
            {
                "id": "SMBCC7",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2565.jpg"
            },
            {
                "id": "SMBCC3",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2545.jpg"
            },
            {
                "id": "SMBCC4",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2552.jpg"
            },
            {
                "id": "SMBCC5",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2554.jpg"
            },
            {
                "id": "SMBCC6",
                "url": "https://chess-la.s3.us-west-1.amazonaws.com/chessSites/sm_bay_chess_club/IMG_2555.jpg"
            },
            
        ]
    },
    "sgvchess_club": {
        id: "sgvchess_club",
        name: "San Gabriel Valley Chess Club",
        photo_thumbnail: `${baseURL}/chessboard_dark.jpg`,
        position: { lat: 34.190648324627844, lng: -118.13055201829279 },
        boardType: "club",
        neighborhood: "Altadena",
        notes: `The SGV Chess Clubs meets Tuesdays from 6:00pm - 10:00pm in Heicher Hall, 
        a multipurpose room at Altadena Community Church. Active USCF membership is required to play.
        `,
        purchase_required: false,
        last_confirmed_seen: "Unknown",
        verified: false,
        socials: [
            { iconType: "gmap", url: "https://goo.gl/maps/KXfLDj7B4HkczAyS9" },
            { iconType: "website", url: "https://sgvchess.club/" },
        ],
    },
};