import React from "react";
import App from "./App";
import ChessSite from "./components/chessSites/ChessSite";
import Thanks from "./components/thanks/thanks";
import About from "./components/about/About";
import ChessSiteCardGallery from "./components/chessSiteCards/ChessSiteCardGallery"; 
import { createRoot } from 'react-dom/client';
import Calendar from "./components/calendar/Calendar";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import "./index.css";

function Routing(props) {
    return (
        <BrowserRouter>
        <Routes>
          <Route path="/gallery" element={<ChessSiteCardGallery />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/chess-site/:sitename" element={<ChessSite />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/about" element={<About />} />

          <Route path="/" element={<App />} />
        </Routes>
      </BrowserRouter>
    );
  }

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Routing />);
