import React from "react";
import InfoWindow from "./infowindows/info-window";
import Marker from "./markers/marker";
import Map from "./map";
import Legend from "./legend/Legend";
// import TestControl from "./customControls/TestControl";
import { staticPlaceInfo } from "../../constants/placeIds";
import { tables } from "../../constants/chessSites/tables";
import { clubs } from "../../constants/chessSites/clubs";
import { lessons } from "../../constants/chessSites/lessons";
import { world } from "../../constants/chessSites/world";
import mapStyles from "./Chessmap.styles";
import "./ChessMap.styles.css";

export default function ChessMap() {
  
  let chessSites = {
    ...clubs,
    ...staticPlaceInfo,
    ...tables,
    ...lessons,
    ...world
  };

  // const otherFunc = (chessSite) => { 
  //   const images = chessSite.images;
  //   images.forEach((image, index) => (
  //     new Image().src = image.url
  //   ));
  // };

  // useEffect(() => {
  //   if (chessSites) {

  //     chessSites.forEach((chessSite, index) => (
  //       otherFunc(chessSite)
  //     ));

  //   }
  // }, []);

  const markers = Object.keys(chessSites).map(key => {
      return chessSites[key].verified ?
        (<Marker
          key={chessSites[key].id}
          position={chessSites[key].position}
          boardType={chessSites[key].boardType}
          category={chessSites[key].boardType}
        >
          <InfoWindow content={chessSites[key]}></InfoWindow>
        </Marker>)
        :
        (<></>)
    }
  );

  // const createMarkers = Object.keys(chessSites).map(key =>
  //   (<Marker
  //     key={chessSites[key].id}
  //     position={chessSites[key].position}
  //     boardType={chessSites[key].boardType}
  //     category={chessSites[key].boardType}
  //   >
  //     <InfoWindow content={chessSites[key]}></InfoWindow>
  //   </Marker>)
  //   );
  
  return (
    <Map 
      id="map" 
      apiKey={"AIzaSyD2WfUj44RwxVgu-ODeof6IMFzwY9IcqzI"} 
      center={{lat: 34.0460409, lng: -118.2556112}} 
      zoom={10} 
      mapTypeControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      rotateControl={false}
      scaleControl={false}
      styles={mapStyles}
      zoomControl={false}
    >
      {/* <TestControl markers={markers}/> */}
      <Legend />
      {markers}
    </Map>
  );
}
