import React from "react";
import Map from "../map/map";
import "./ChessSiteMap.styles.css";
import Marker from "../map/markers/marker";
import chessSiteMapStyles from "./ChessSiteMap.styles";

export default function ChessSiteMap(props) {
  let chessSite = props.chessSite;

  return (
    <>
          <div className="chessSiteMap">
            <Map 
              id="chessSiteMap"
              apiKey={"AIzaSyD2WfUj44RwxVgu-ODeof6IMFzwY9IcqzI"}
              center={chessSite.position}
              zoom={15}
              mapTypeControl={false}
              fullscreenControl={false}
              streetViewControl={false}
              rotateControl={false}
              scaleControl={false}
              styles={chessSiteMapStyles}
              zoomControl={false}
              keyboardShortcuts={false}
            // mapTypeId={'satellite'}
            >
            <Marker
              key="chessSite"
              position={chessSite.position}
              boardType={chessSite.boardType}
            />
          </Map>
        </div>
    </>
  );
}
