import { useEffect, useState } from "react";
import "./InfoWindow.styles.css";

const InfoWindow = ({ content, anchor, map, open, onClose, ...props }) => {
  const [infoWindow, setInfoWindow] = useState();

  const infoContent = (place) => {
    return (
      `<div>
          <h3>${place.name}</h3>
          <a class="card" href="/chess-site/${place.id}" style="background-image: url('${place.photo_thumbnail}');">
          <div class="container">
              <div class="tags">
                <div class="tag">${place.boardType}</div>
              </div>
          </div>
          </a>
      </div>`
    );
  };
  
  const openInfoWindow = () => { 
    infoWindow.open({
      anchor: anchor,
      map,
      shouldFocus: false
    });
  };

  useEffect(() => {
    if (open && infoWindow) {
      let id = `infowindow_${Math.random() * 1000000}`;
      infoWindow.setContent(`<div id=${id}>${infoContent(content)}</div>`);

      openInfoWindow();

      // infoWindow.addListener('closeclick', function() {
      //   // keep this in case we want to add closeclick handling
      // });

      infoWindow.anchor.addListener('mousedown', function() {
        openInfoWindow();
      });
    }
  }, [open, anchor, map, infoWindow, content]);

  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new window.google.maps.InfoWindow({
        id: 'test',
        map: map,
        shadowStyle: 1,
        padding: 0,
        borderRadius: 4,
        arrowSize: 10,
        borderWidth: 1,
        disableAutoPan: true,
        hideCloseButton: true,
        arrowPosition: 100,
        arrowStyle: 5
      }));
    }

    // remove marker from map on unmount
    return () => {
      if (infoWindow) {
        infoWindow.setMap(null);
      }
    };
  }, [infoWindow]);

  useEffect(() => {

    if (infoWindow) {
      infoWindow.setOptions(props);
    }

  }, [infoWindow, props, onClose]);

  return null;
};
InfoWindow.displayName = "InfoWindow";

export default InfoWindow;