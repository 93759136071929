import React from "react";
import ChessSiteInfo from "./ChessSiteInfo";
import Menu from "../menu/Menu";
import "./ChessSite.styles.css";

export default function ChessSite() {
  return (
    <>
      <Menu />
        <div className="chessSiteInfoColumn">
          <ChessSiteInfo/>
        </div>
    </>
  );
}
