import React from "react";
import Menu from "../menu/Menu";
import "../common/Common.styles.css";


export default function Thanks(props) {

    return (
        <>
            <Menu />
            <div className="centeredColumnContainer">
                <p>
                    Chess icons created by <a href="https://www.flaticon.com/free-icons/chess" target="_blank" rel="noopener noreferrer" title="chess icons">Freepik - Flaticon</a>:
                    <a href="https://www.flaticon.com/free-icon/chess-board_3522641" target="_blank" rel="noopener noreferrer" title="chess icons">Chess Board</a>, 
                    <a href="https://www.flaticon.com/free-icon/table_3190298" target="_blank" rel="noopener noreferrer" title="chess icons">Table</a>, 
                    <a href="https://www.flaticon.com/free-icon/teamwork_1256661" target="_blank" rel="noopener noreferrer" title="chess icons">Teamwork</a>, 
                    <a href="https://www.flaticon.com/free-icon/chess-board_3555417" target="_blank" rel="noopener noreferrer" title="chess icons">Chess Board</a>, 
                    <a href="https://www.flaticon.com/free-icon/trophy_2641497" target="_blank" rel="noopener noreferrer" title="chess icons">Trophy</a>, 
                    <a href="https://www.flaticon.com/free-icon/museum_508875" target="_blank" rel="noopener noreferrer" title="chess icons">Museum</a>, 
                    <a href="https://www.flaticon.com/free-icon/google-maps_355980" target="_blank" rel="noopener noreferrer" title="chess icons">Map</a>,
                    <a href="https://www.flaticon.com/premium-icon/school_1048947" target="_blank" rel="noopener noreferrer" title="chess icons">School</a>,
                    <a href="https://www.flaticon.com/premium-icon/world-wide-web_1927768" target="_blank" rel="noopener noreferrer" title="chess icons">World Wide Web</a>.

                </p>
                
                <p>
                    Icons created by <a href="https://www.flaticon.com/authors/pixel-buddha" target="_blank" rel="noopener noreferrer" title="chess icons">Pixel Buddha - Flaticon</a>:
                    <a href="https://www.flaticon.com/free-icon/twitter_179342" target="_blank" rel="noopener noreferrer" title="chess icons">Twitter</a>, 
                </p>
                <p>
                    Icons created by <a href="https://www.flaticon.com/authors/laisa-islam-ani" target="_blank" rel="noopener noreferrer" title="chess icons">Laisa Islam Ani - Flaticon</a>:
                    <a href="https://www.flaticon.com/premium-icon/instagram_3955024" target="_blank" rel="noopener noreferrer" title="chess icons">Instagram</a>.
                </p>
                <p>
                    Icons created by <a href="https://www.flaticon.com/authors/md-tanvirul-haque" target="_blank" rel="noopener noreferrer" title="chess icons">Md Tanvirul Haque - Flaticon</a>:
                    <a href="https://www.flaticon.com/premium-icon/meetup_3670333" target="_blank" rel="noopener noreferrer" title="chess icons">Meetup</a>,
                    <a href="https://www.flaticon.com/premium-icon/youtube_3670147" target="_blank" rel="noopener noreferrer" title="chess icons">YouTube</a>.
                </p>
                <p>
                    Icons created by <a href="https://www.flaticon.com/authors/fathema-khanom" target="_blank" rel="noopener noreferrer" title="chess icons">Fathema Khanom - Flaticon</a>:
                    <a href="https://www.flaticon.com/premium-icon/facebook_3670032" target="_blank" rel="noopener noreferrer" title="chess icons">Facebook</a>.
                </p>
                <p>
                    Icons created by <a href="https://www.flaticon.com/authors/grow-studio" target="_blank" rel="noopener noreferrer" title="chess icons">Grow studio - Flaticon</a>:
                    <a href="https://www.flaticon.com/premium-icon/tik-tok_4138151" target="_blank" rel="noopener noreferrer" title="chess icons">Tik Tok</a>.
                </p>

            </div>
        </>
    );
}
