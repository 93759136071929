import React from "react";
import { cardIconMap } from "../../constants/iconMap";
import "./ChessSiteStatIcon.styles.css";


export default function ChessSiteStatIcon(props) {

    const socials = props.socials;

    return (
        <div className="cardStatIconContainer">
            {socials.map(
                image => (
                    <div className="cardStatIcon">
                        <a href={image.url} target="_blank" rel="noopener noreferrer" alt={cardIconMap[image.iconType]}>
                            <img
                                src={cardIconMap[image.iconType]}
                                alt="Social Media Icon" />
                        </a>
                    </div>
                ))
            }
        </div>
    );
}
