import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import ChessSiteCard from "../chessSiteCards/ChessSiteCard";
import { tables } from "../../constants/chessSites/tables";
import { clubs } from "../../constants/chessSites/clubs";
import { staticPlaceInfo } from "../../constants/placeIds";
import { lessons } from "../../constants/chessSites/lessons";
import { world } from "../../constants/chessSites/world";
import "../chessSiteCards/ChessSiteCardGallery.styles.css";

export default function ChessSiteInfo(props) {
    const [chessSite, setChessSite] = useState({});
    const [chessSites, setChessSites] = useState({});
    const [chessJsx, setChessJsx] = useState(null);
    const match = useMatch('/chess-site/:sitename');

    useEffect(() => {
        if (Object.keys(chessSites).length === 0) {
            setChessSites({
                ...clubs,
                ...tables,
                ...staticPlaceInfo,
                ...lessons,
                ...world
            });
        }

    }, []);

    useEffect(() => {
        if (chessSites && Object.keys(chessSites).length > 0) {
            setChessSite(chessSites[match.params.sitename]);
        }

    }, [chessSites]);

    useEffect(() => {
        if (Object.keys(chessSites).length > 0) {
            setChessJsx(
                <ChessSiteCard key={chessSite} chessSite={chessSite} />
            );
        }

    }, [chessSite]);

    return (
        <>
            <div className="chessSiteCardContainer">
                {chessJsx}
            </div>
        </>
    );
}
